import { ErrorResponseBody } from '@backstage/errors';
import _ from 'lodash';

export function parseErrorMessage(error: any) {
  function parseDefaultError(e: any) {
    return e.data?.error?.message ?? e.message;
  }
  try {
    const errorResponse: ErrorResponseBody = JSON.parse(error.message);
    return errorResponse?.error?.message ?? parseDefaultError(error);
  } catch (_parseError) {
    return parseDefaultError(error);
  }
}

/**
 * @todo improve this function
 */
export function parseErrorStatusCode(errorMessage: string): number | undefined {
  try {
    const parsedError = JSON.parse(errorMessage);
    const statusCode = _.get(parsedError, 'response.statusCode');
    return typeof statusCode === 'number' && !isNaN(statusCode)
      ? statusCode
      : undefined;
  } catch (error) {
    return undefined;
  }
}
