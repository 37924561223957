import React, { ComponentProps } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { FavoriteFilled } from '@agilelab/plugin-wb-platform';
import FavoriteBorder from '@material-ui/icons/StarBorder';
import { useStarredEntity } from '@backstage/plugin-catalog-react';
import { CompoundEntityRef, Entity } from '@backstage/catalog-model';

type FavoriteEntityButtonProps = ComponentProps<typeof IconButton> & {
  entity: Entity | CompoundEntityRef | string;
};

export const WbFavoriteEntityButton: React.FC<FavoriteEntityButtonProps> = ({
  entity,
  ...props
}) => {
  const { toggleStarredEntity, isStarredEntity } = useStarredEntity(entity);

  return (
    <IconButton
      size="small"
      aria-label="favorite"
      color="secondary"
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        toggleStarredEntity();
      }}
      {...props}
    >
      <Tooltip
        title={isStarredEntity ? 'Remove from favorites' : 'Add to favorites'}
      >
        {isStarredEntity ? <FavoriteFilled /> : <FavoriteBorder />}
      </Tooltip>
    </IconButton>
  );
};
