import { GovernancePolicyFlags, PolicyViolationOutcome } from '../types';

export function violationsCountToGovernanceFlags(
  count: Map<PolicyViolationOutcome, number>,
): GovernancePolicyFlags {
  return {
    info: count.get('info') ?? 0,
    warning:
      (count.get('warning') ?? 0) + (count.get('not_blocking_error') ?? 0),
    error: count.get('error') ?? 0,
  };
}
