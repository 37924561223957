import { useAnalytics, useElementFilter } from '@backstage/core-plugin-api';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, {
  CSSProperties,
  forwardRef,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Link,
  NavLinkProps,
  useLocation,
  useResolvedPath,
} from 'react-router-dom';

import Button from '@material-ui/core/Button';
import ChevronRight from '@material-ui/icons/ChevronRight';
import {
  SidebarConfig,
  SubmenuModeEnum,
  WbSidebarConfigContext,
  WbSidebarItemWithSubmenuContext,
} from '../config';
import { IconType } from '../types';
import { isLinkActive, useLocationMatch } from '../utils';
import { useWbSidebarOpenState } from '../WbSidebarOpenStateContext';
import { WbSidebarSubmenu, WbSidebarSubmenuProps } from '../WbSidebarSubmenu';
import { useWbSidebarPinState } from '../WbSidebarPinStateContext';

/** @public */
export type WbSidebarItemClassKey =
  | 'root'
  | 'buttonItem'
  | 'headerItem'
  | 'wrapperItem'
  | 'closed'
  | 'open'
  | 'highlightable'
  | 'highlighted'
  | 'label'
  | 'iconContainer'
  | 'secondaryAction'
  | 'closedItemIcon'
  | 'submenuArrow'
  | 'selected';

const makeSidebarStyles = (sidebarConfig: SidebarConfig) =>
  makeStyles(
    theme => ({
      root: {
        color: theme.palette.secondary.main,
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        height: sidebarConfig.itemHeight,
        padding: '20px 0px',
        cursor: 'pointer',
        borderRadius: '4px',
      },
      buttonItem: {
        border: 'none',
        width: '100%',
        margin: 0,
        padding: 0,
        textAlign: 'inherit',
        font: 'inherit',
        textTransform: 'none',
        minWidth: 0,
      },
      headerItem: {
        color: theme.palette.primary.main,
        cursor: 'auto',
        padding: '8px 0px 0px 0px',
        marginBottom: '-4px',
        marginLeft: '-30px',
        '& $label': {
          fontSize: theme.typography.body2.fontSize,
        },
      },
      closed: {
        width: sidebarConfig.drawerWidthClosed,
        justifyContent: 'center',
      },
      open: {
        width: sidebarConfig.drawerWidthOpen,
      },
      highlightable: {
        '&:hover': {
          background: theme.palette.bkg.primary,
        },
      },
      highlighted: {
        background: theme.palette.bkg.primary,
      },
      label: {
        whiteSpace: 'nowrap',
        flex: '3 1 auto',
        width: '110px',
        overflow: 'hidden',
        fontSize: theme.typography.body2.fontSize,
        'text-overflow': 'ellipsis',
      },
      wrapperItem: {
        padding: `0px ${sidebarConfig.itemSidePadding}px`,
      },
      iconContainer: {
        boxSizing: 'border-box',
        height: '100%',
        width: sidebarConfig.iconContainerWidth,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        lineHeight: '0',
      },
      secondaryAction: {
        marginLeft: 'auto',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: theme.spacing(1),
      },
      closedItemIcon: {
        width: '100%',
        justifyContent: 'center',
      },
      icon: {
        fontSize: '20px',
        color: theme.palette.secondary.main,
      },
      submenuArrow: {
        display: 'flex',
        fontSize: theme.typography.body2.fontSize,
      },
      notificationsBadge: {
        '& .MuiBadge-badge': {
          right: 8,
          top: '50%',
          backgroundColor: theme.palette.accent.main,
        },
      },

      selected: {
        backgroundColor: theme.palette.softened.primary,
      },
    }),
    { name: 'WitboostWbSidebarItem' },
  );

// This is a workaround for this issue https://github.com/mui/material-ui/issues/15511
// The styling of the `selected` elements doesn't work as expected when using a prop callback.
// Don't use this pattern unless needed
function useMemoStyles(sidebarConfig: SidebarConfig) {
  const useStyles = useMemo(
    () => makeSidebarStyles(sidebarConfig),
    [sidebarConfig],
  );
  return useStyles();
}

type WbSidebarItemBaseProps = {
  icon?: IconType;
  text?: string;
  hasNotifications?: boolean;
  hasSubmenu?: boolean;
  disableHighlight?: boolean;
  className?: string;
  noTrack?: boolean;
  onClick?: (ev: React.MouseEvent) => void;
};

type WbSidebarItemButtonProps = WbSidebarItemBaseProps & {
  onClick: (ev: React.MouseEvent) => void;
  children?: ReactNode;
};

type WbSidebarItemLinkProps = WbSidebarItemBaseProps & {
  to: string;
  excludedActivationPaths?: string[];
  extraActivationPaths?: string[];
  onClick?: (ev: React.MouseEvent) => void;
} & NavLinkProps;

type WbSidebarItemWithSubmenuProps = WbSidebarItemBaseProps & {
  onClick?: (ev: React.MouseEvent) => void;
  children: ReactNode;
  submenumode?: SubmenuModeEnum;
};

type WbSidebarItemHeaderProps = WbSidebarItemBaseProps & {
  isHeader: true;
};

/**
 * WbSidebarItem with 'to' property will be a clickable link.
 * WbSidebarItem with 'onClick' property and without 'to' property will be a clickable button.
 * WbSidebarItem which wraps a SidebarSubmenu will be a clickable button which opens a submenu.
 */
export type WbSidebarItemProps =
  | WbSidebarItemLinkProps
  | WbSidebarItemButtonProps
  | WbSidebarItemWithSubmenuProps
  | WbSidebarItemHeaderProps;

function isButtonItem(
  props: WbSidebarItemProps,
): props is WbSidebarItemButtonProps {
  return (
    (props as WbSidebarItemLinkProps).to === undefined &&
    props.onClick !== undefined
  );
}

function isLinkItem(
  props: WbSidebarItemProps,
): props is WbSidebarItemLinkProps {
  return (props as WbSidebarItemLinkProps).to !== undefined;
}

function isHeaderItem(
  props: WbSidebarItemProps,
): props is WbSidebarItemHeaderProps {
  return (props as WbSidebarItemHeaderProps).isHeader;
}

const sidebarSubmenuType = React.createElement(WbSidebarSubmenu).type;

export const WorkaroundNavLink = React.forwardRef<
  HTMLAnchorElement,
  NavLinkProps &
    Pick<
      WbSidebarItemLinkProps,
      'excludedActivationPaths' | 'extraActivationPaths'
    > & {
      hasSubmenu?: boolean;
      children?: ReactNode;
      activeStyle?: CSSProperties;
      activeClassName?: string;
    }
>(function WorkaroundNavLinkWithRef(
  {
    to,
    end,
    style,
    className,
    activeStyle,
    caseSensitive,
    extraActivationPaths = [],
    excludedActivationPaths = [],
    activeClassName = 'active',
    hasSubmenu,
    'aria-current': ariaCurrentProp = 'page',
    ...rest
  },
  ref,
) {
  const { pathname: locationPathname } = useLocation();
  const { pathname: toPathname } = useResolvedPath(to);

  const isActive = isLinkActive(
    locationPathname,
    toPathname,
    caseSensitive,
    end,
    extraActivationPaths,
    excludedActivationPaths,
  );

  const ariaCurrent = isActive ? ariaCurrentProp : undefined;

  return (
    <Link
      {...rest}
      to={to}
      ref={ref}
      aria-current={ariaCurrent}
      style={{ ...style, ...(isActive ? activeStyle : undefined) }}
      className={clsx([
        typeof className !== 'function' ? className : undefined,
        isActive ? activeClassName : undefined,
      ])}
    />
  );
});

const WbSidebarItemContent = ({
  icon: Icon,
  text,
  classes,
  hasNotifications,
  children,
}: WbSidebarItemProps & {
  classes: ReturnType<typeof useMemoStyles>;
  children: ReactNode;
}) => {
  const { isOpen } = useWbSidebarOpenState();
  const displayItemIcon = Icon && (
    <Box style={{ lineHeight: '0' }}>
      <Icon className={classes.icon} />
    </Box>
  );

  const itemIcon = (
    <Badge
      color="secondary"
      variant="dot"
      overlap="circular"
      invisible={!hasNotifications || isOpen}
      className={clsx(classes.notificationsBadge, {
        [classes.closedItemIcon]: !isOpen,
      })}
    >
      {displayItemIcon}
    </Badge>
  );

  const openContent = (
    <>
      <Box data-testid="wb-sidebar-item-icon" className={classes.iconContainer}>
        {itemIcon}
      </Box>
      {text && (
        <Typography component="span" className={classes.label}>
          {text}
        </Typography>
      )}
      {children !== undefined && (
        <div className={classes.secondaryAction}>{children}</div>
      )}
    </>
  );

  return <>{isOpen ? openContent : itemIcon}</>;
};

/**
 * Common component used by WbSidebarItem & WbSidebarItemWithSubmenu
 */
export const WbSidebarItemBase = forwardRef<
  any,
  WbSidebarItemProps & { children: ReactNode }
>((props, ref) => {
  const {
    icon: Icon,
    text,
    hasNotifications = false,
    disableHighlight = false,
    onClick,
    noTrack,
    children,
    className,
    ...navLinkProps
  } = props;
  const { sidebarConfig } = useContext(WbSidebarConfigContext);
  const { isOpen } = useWbSidebarOpenState();

  const classes = useMemoStyles(sidebarConfig);

  const childProps = {
    onClick,
    className: clsx(
      className,
      classes.root,
      isButtonItem(props) && classes.buttonItem,
      { [classes.highlightable]: !disableHighlight },
    ),
  };

  const wrapperProps = {
    className: clsx(
      classes.wrapperItem,
      isOpen ? classes.open : classes.closed,
    ),
  };

  const analyticsApi = useAnalytics();
  const { pathname: to } = useResolvedPath(
    isLinkItem(props) && props.to ? props.to : '',
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
      if (!noTrack) {
        const action = 'click';
        const subject = text ?? 'Sidebar Item';
        const options = to ? { attributes: { to } } : undefined;
        analyticsApi.captureEvent(action, subject, options);
      }
      onClick?.(event);
    },
    [analyticsApi, text, to, noTrack, onClick],
  );

  // header item
  if (isHeaderItem(props))
    return (
      <Box {...wrapperProps}>
        <Box className={clsx(className, classes.root, classes.headerItem)}>
          <WbSidebarItemContent {...props} classes={classes} />
        </Box>
      </Box>
    );

  // button item
  if (isButtonItem(props)) {
    return (
      <Box {...wrapperProps}>
        <Button
          role="button"
          aria-label={text}
          {...childProps}
          ref={ref}
          onClick={handleClick}
        >
          <WbSidebarItemContent {...props} classes={classes} />
        </Button>
      </Box>
    );
  }

  // link item
  if (isLinkItem(props)) {
    return (
      <Box {...wrapperProps}>
        <WorkaroundNavLink
          {...childProps}
          activeClassName={classes.selected}
          to={props.to ? props.to : ''}
          ref={ref}
          aria-label={text ? text : props.to}
          {...navLinkProps}
          onClick={handleClick}
        >
          <WbSidebarItemContent {...props} classes={classes} />
        </WorkaroundNavLink>
      </Box>
    );
  }

  // the props didn't match any supported configuration, something went wrong
  throw new Error('Invalid SidebarItem props');
});

const WbSidebarItemWithSubmenu = ({
  children,
  onClick,
  ...props
}: WbSidebarItemBaseProps & {
  submenumode: SubmenuModeEnum;
  children: React.ReactElement<WbSidebarSubmenuProps>;
}) => {
  const { setTemporarilyPinned } = useWbSidebarPinState();
  const { sidebarConfig } = useContext(WbSidebarConfigContext);
  const { level: parentLevel } = useContext(WbSidebarItemWithSubmenuContext);
  const classes = useMemoStyles(sidebarConfig);
  const [isSubmenuOpen, setIsSubMenuOpen] = useState(false);
  const location = useLocation();
  const isActive = useLocationMatch(children, location);

  const handleSubmenuOpen = () => {
    setIsSubMenuOpen(true);
  };

  const handleSubmenuClose = () => {
    setIsSubMenuOpen(false);
  };

  const arrowIcon = () => {
    return <ChevronRight fontSize="small" className={classes.submenuArrow} />;
  };

  // default mode if not specified is overlay
  const submenuMode = props.submenumode ?? SubmenuModeEnum.overlay;

  useEffect(() => {
    if (submenuMode === SubmenuModeEnum.overlay) {
      // if the submenu is active, it means we are in one of its children route therefore we temporarily pin the sidebar and set the submenu over it
      setTemporarilyPinned(isActive);
      setIsSubMenuOpen(isActive);
    }
  }, [setIsSubMenuOpen, isActive, submenuMode, setTemporarilyPinned]);

  return (
    <WbSidebarItemWithSubmenuContext.Provider
      value={{
        submenuMode: submenuMode,
        isSubmenuOpen,
        level: parentLevel !== undefined ? parentLevel + 1 : 0,
      }}
    >
      <div
        data-testid="item-with-submenu"
        onMouseLeave={() =>
          props.submenumode === SubmenuModeEnum.overlay
            ? null
            : handleSubmenuClose()
        }
      >
        <WbSidebarItemBase
          hasSubmenu
          className={clsx(
            isActive && classes.selected,
            isSubmenuOpen && classes.highlighted,
          )}
          onClick={(ev: React.MouseEvent) => {
            handleSubmenuOpen();
            if (props.submenumode === SubmenuModeEnum.side) {
              ev.preventDefault();
            }
            onClick?.(ev);
          }}
          {...props}
        >
          {arrowIcon()}
        </WbSidebarItemBase>
        {children}
      </div>
    </WbSidebarItemWithSubmenuContext.Provider>
  );
};

/**
 * Creates a `WbSidebarItem`
 *
 * @remarks
 * If children contain a `SidebarSubmenu` component the `WbSidebarItem` will have a expandable submenu
 */
export const WbSidebarItem = forwardRef<
  any,
  WbSidebarItemProps & { children: ReactNode; submenumode: SubmenuModeEnum }
>((props, ref) => {
  // Filter children for SidebarSubmenu components
  const [submenu] = useElementFilter(props.children, elements =>
    // Directly comparing child.type with SidebarSubmenu will not work with in
    // combination with react-hot-loader
    //
    // https://github.com/gaearon/react-hot-loader/issues/304#issuecomment-456569720
    elements.getElements().filter(child => child.type === sidebarSubmenuType),
  );

  if (submenu) {
    return (
      <WbSidebarItemWithSubmenu {...props}>
        {submenu as React.ReactElement<WbSidebarSubmenuProps>}
      </WbSidebarItemWithSubmenu>
    );
  }

  return <WbSidebarItemBase {...props} ref={ref} />;
}) as (props: WbSidebarItemProps) => JSX.Element;
