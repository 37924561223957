import { SkeletonParameters } from '@agilelab/plugin-wb-catalog-extension-common';
import z from 'zod';

export const ComputeSkeletonEntityUpdatesRequestSchema = z.object({
  currentSkeleton: z.object({
    catalogInfo: z.string(),
    parameters: z.object({
      parameters: z.record(z.any()),
      values: z.record(z.any()).optional(),
      readonly: z.record(z.any()).optional(),
    }),
  }),
  upserts: z.object({
    parameters: z.object({
      parameters: z.record(z.any()),
      values: z.record(z.any()).optional(),
      readonly: z.record(z.any()).optional(),
    }),
  }),
  computeRenderedData: z.boolean(),
});

export type ComputeSkeletonEntityUpdatesRequest = z.infer<
  typeof ComputeSkeletonEntityUpdatesRequestSchema
>;

export type ComputeSkeletonEntityUpdatesResponse = {
  updatedSkeleton: {
    parameters: SkeletonParameters;
  };
  renderedData?: {
    catalogInfo: Record<string, any>;
  };
};
