import { createSvgIcon } from '@material-ui/core';
import React from 'react';

// overridable color
export const Hexagon = createSvgIcon(
  <>
    <svg
      viewBox="0 0 220 187"
      xmlns="http://www.w3.org/2000/svg"
      overflow="visible"
    >
      <path
        d="M60.5005 0C56.5391 0 52.8735 2.06288 50.865 5.42261L1.54355 87.9226C-0.514513 91.3651 -0.514518 95.6348 1.54354 99.0774L50.865 181.577C52.8735 184.937 56.5391 187 60.5005 187H159.499C163.461 187 167.126 184.937 169.135 181.577L218.456 99.0774C220.514 95.6349 220.514 91.3651 218.456 87.9226L169.135 5.42262C167.126 2.06288 163.461 0 159.499 0H60.5005Z"
        fill="currentColor"
      />
    </svg>
  </>,
  'Hexagon',
);
