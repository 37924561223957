import { createSvgIcon } from '@material-ui/core';
import React from 'react';

export const DisabledHexagon = createSvgIcon(
  <>
    <svg viewBox="0 0 120 102" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4612 3.98405L2.55856 48.984C1.81381 50.2298 1.81381 51.7702 2.55856 53.016L29.4612 98.016C30.1903 99.2357 31.5328 100 33.0003 100H86.9997C88.4673 100 89.8097 99.2357 90.5389 98.016L117.441 53.016C118.186 51.7702 118.186 50.2298 117.441 48.984L90.5389 3.98405C89.8097 2.76432 88.4672 2 86.9997 2H33.0003C31.5328 2 30.1904 2.76432 29.4612 3.98405ZM27.7445 2.95779C28.8401 1.12521 30.8395 0 33.0003 0H86.9997C89.1605 0 91.1599 1.12521 92.2555 2.95779L119.158 47.9578C120.281 49.8355 120.281 52.1645 119.158 54.0422L92.2555 99.0422C91.1599 100.875 89.1605 102 86.9997 102H33.0003C30.8395 102 28.8401 100.875 27.7445 99.0422L0.841933 54.0422C-0.280646 52.1645 -0.280644 49.8355 0.841936 47.9578L27.7445 2.95779Z"
        fill="#E0E0E0"
      />
    </svg>
  </>,
  'DisabledHexagon',
);
