import React from 'react';
import { Badge, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

type TypeBadgeProps = {
  typeDisplayName: string;
  kind: string;
};

const useStyles = makeStyles(
  theme => ({
    root: {
      borderRadius: theme.spacing(0.5),
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      ...theme.typography.body2,
      color: theme.palette.white,
      fontWeight: 500,
      whiteSpace: 'nowrap',
    },
    system: {
      backgroundColor: theme.palette.relationsGraph.system,
    },
    component: {
      backgroundColor: theme.palette.relationsGraph.component,
    },
  }),
  { name: 'TypeBadge' },
);

export const TypeBadge = ({ typeDisplayName, kind }: TypeBadgeProps) => {
  const classes = useStyles();
  return (
    <Badge
      overlap="rectangular"
      className={clsx(classes.root, (classes as any)[kind])}
    >
      {typeDisplayName}
    </Badge>
  );
};
