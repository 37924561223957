/* eslint-disable no-console */
import { useNotification } from '@agilelab/plugin-wb-notification';
import {
  customFrontendApiRef,
  CustomMicrofrontendOptions,
  CustomMicrofrontendTarget,
  MaintenancePage,
  SubmenuModeEnum,
  useEnabledFeatures,
  useHomeUrl,
  WbSidebar,
  WbSidebarAvatar,
  WbSidebarDivider,
  WbSidebarGroup,
  WbSidebarItem,
  WbSidebarLogo,
  WbSidebarNotificationsItem,
  WbSidebarPage,
  WbSidebarScrollWrapper,
  WbSidebarSpace,
  WbSidebarSubmenu,
  WbSidebarSubmenuItem,
} from '@agilelab/plugin-wb-platform';
import { practiceShaperApiRef } from '@agilelab/plugin-wb-practice-shaper';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import BallotIcon from '@material-ui/icons/Ballot';
import FolderIcon from '@material-ui/icons/Folder';
import HomeIcon from '@material-ui/icons/Home';
import ListIcon from '@material-ui/icons/List';
import ListAltIcon from '@material-ui/icons/ListAlt';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import { useQuery } from '@tanstack/react-query';
import React, { PropsWithChildren } from 'react';
import {
  BlueprintIcon,
  CreateIcon,
  GovernanceRegistryIcon,
  GovernanceSearchResultsIcon,
  MarketplaceGraphIcon,
  MyDataProductIcon,
  PoliciesOverviewIcon,
  SoftwareCatalogIcon,
} from './Icons/Icons';

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const { loading: enabledFeaturesLoading, data: features } =
    useEnabledFeatures();
  const practiceShaperApi = useApi(practiceShaperApiRef);
  const customFrontendApi = useApi(customFrontendApiRef);
  const identityApi = useApi(identityApiRef);

  const { homeURL, loading, isGuestUser } = useHomeUrl();

  const { data: isMaintenanceActive } = useQuery({
    queryKey: ['maintenanceActive'],
    queryFn: async () => {
      return practiceShaperApi.isMaintenanceActive(
        await identityApi.getCredentials(),
      );
    },
  });

  const { unreadNotificationCount } = useNotification();

  if (enabledFeaturesLoading) {
    return <></>;
  }

  if (isMaintenanceActive) {
    return <MaintenancePage />;
  }

  const getCustomFrontends = customFrontendApi.makeCustomMicrofrontends(
    CustomMicrofrontendTarget.MarketplacePage,
  );
  const customPages: CustomMicrofrontendOptions[] = getCustomFrontends();

  const getDefaultSettingPage = () => {
    if (features.meshSupervisionEnabled || features.platformSupervisionEnabled)
      return '/platform-settings/supervision';
    if (features.practiceShaperEnabled)
      return '/platform-settings/practice-shaper';
    return '/platform-settings/access-control';
  };

  return (
    <WbSidebarPage>
      <WbSidebar>
        {!loading && <WbSidebarLogo to={homeURL} />}
        <WbSidebarDivider noAboveMargin />
        <WbSidebarScrollWrapper hoverOnlyScrollbar>
          {features.marketplaceEnabled && !isGuestUser && (
            <WbSidebarGroup text="Data Marketplace" invisibleDivider>
              {features.marketplaceCatalogEnabled && (
                <WbSidebarItem
                  icon={HomeIcon}
                  to="marketplace/home"
                  text="Home"
                />
              )}
              {features.marketplaceCatalogEnabled && (
                <WbSidebarItem
                  icon={SearchIcon}
                  extraActivationPaths={['marketplace/consumable-interface/']}
                  to="marketplace/search"
                  text="Search"
                />
              )}
              {features.marketplaceCatalogEnabled &&
                features.listPageEnabled && (
                  <WbSidebarItem
                    icon={ListIcon}
                    to="marketplace/list"
                    text="List"
                  />
                )}
              {features.marketplaceGraphEnabled && (
                <WbSidebarItem
                  icon={MarketplaceGraphIcon}
                  to="marketplace/visual-discovery"
                  text="Visual Discovery"
                />
              )}
              {customPages.map(page => (
                <WbSidebarItem
                  key={page.title}
                  icon={BallotIcon}
                  text={page.title}
                  to={`marketplace${page.route}`}
                />
              ))}
            </WbSidebarGroup>
          )}
          {features.builderEnabled && (
            <WbSidebarGroup text="Builder">
              {features.templatesEnabled && (
                <WbSidebarItem
                  extraActivationPaths={['scaffolder']}
                  excludedActivationPaths={[
                    'blueprints',
                    'scaffolder/blueprints',
                  ]}
                  icon={CreateIcon}
                  to="templates"
                  text="Templates"
                />
              )}
              {features.blueprintsEnabled && (
                <WbSidebarItem
                  extraActivationPaths={['blueprints', 'scaffolder/blueprints']}
                  icon={BlueprintIcon}
                  to="blueprints"
                  text="Blueprints"
                />
              )}
              {features.catalogEnabled && (
                <>
                  <WbSidebarItem
                    icon={MyDataProductIcon}
                    to="my-projects/catalog"
                    text="My Projects"
                  />
                  {features.softwareCatalogEnabled && (
                    <WbSidebarItem
                      icon={SoftwareCatalogIcon}
                      to="catalog"
                      text="Software Catalog"
                    />
                  )}
                  {features.prototypePageEnabled && (
                    <WbSidebarItem
                      icon={ListAltIcon}
                      to="prototypes"
                      text="Backlog"
                    />
                  )}
                </>
              )}
            </WbSidebarGroup>
          )}
          {features.governanceEnabled && (
            <WbSidebarGroup text="Governance">
              <WbSidebarItem
                icon={GovernanceRegistryIcon}
                to="/governance/registry"
                text="Registry"
              />
              <WbSidebarItem
                icon={PoliciesOverviewIcon}
                to="/governance/policies-overview"
                text="Policies Overview"
              />
              <WbSidebarItem
                icon={GovernanceSearchResultsIcon}
                to="/governance/search-results"
                text="Search Results"
              />
            </WbSidebarGroup>
          )}
          {features.externalResourcesEnabled && (
            <WbSidebarGroup text="Data Sharing">
              <WbSidebarItem
                icon={FolderIcon}
                to="/external-resources/dashboard"
                text="Dashboard"
              />
              <WbSidebarItem
                icon={FolderIcon}
                to="/external-resources"
                text="Package List"
              />
            </WbSidebarGroup>
          )}
        </WbSidebarScrollWrapper>
        <WbSidebarSpace />
        {(features.notificationsMenuEnabled ||
          features.platformSettingsEnabled) && <WbSidebarDivider sideMargin />}
        {features.notificationsMenuEnabled && (
          <WbSidebarNotificationsItem
            unreadNotifications={unreadNotificationCount}
            icon={NotificationsIcon}
            to="notifications"
            text="Notifications"
          />
        )}
        {features.platformSettingsEnabled && (
          <WbSidebarItem
            icon={SettingsIcon}
            to={getDefaultSettingPage()}
            text="Administration"
            submenumode={SubmenuModeEnum.overlay}
          >
            <WbSidebarSubmenu title="Administration">
              {(features.meshSupervisionEnabled ||
                features.platformSupervisionEnabled) && (
                <WbSidebarSubmenuItem
                  to="/platform-settings/supervision"
                  title="Supervision"
                />
              )}
              {features.practiceShaperEnabled && (
                <WbSidebarSubmenuItem
                  to="/platform-settings/practice-shaper"
                  title="Practice Shaper"
                />
              )}
              <WbSidebarSubmenuItem
                to="/platform-settings/access-control"
                title="Access Control"
              />
            </WbSidebarSubmenu>
          </WbSidebarItem>
        )}

        <WbSidebarDivider noUnderMargin />
        <WbSidebarAvatar to="settings" />
      </WbSidebar>
      {children}
    </WbSidebarPage>
  );
};
