import { useQuery } from '@apollo/client';
import { ErrorPanel, Progress } from '@backstage/core-components';
import {
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import { DateTime } from 'luxon';
import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as ChartTooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {
  useEnvironmentsContext,
  WbCard,
  WbCardContent,
} from '@agilelab/plugin-wb-platform';
import { useTaxonomySelection } from '@agilelab/plugin-wb-practice-shaper';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import pluralize from 'pluralize';
import { NETWORK_EFFECT, ValueByMonth } from '../../graphql';
import { extractTaxonomyUrn, getMonthsBetweenDateAndNow } from '../../utils';
import { buildDashboardWhereQuery, getCardLabel } from './utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartContainer: {
      position: 'relative',
      width: '100%',
      height: '320px',
    },
    networkChartContainerEmpty: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -100%)',
      padding: '12px 16px',
      borderRadius: '4px',
      background: theme.palette.grey[300],
      color: 'black',
    },
  }),
);

function parseData(data: {
  instances: {
    published_at: string;
    inputPorts_aggregate: { aggregate: { count: number } };
  }[];
}): ValueByMonth[] {
  if (!data.instances || data.instances.length === 0) {
    return [];
  }

  const dpInstances = [...data.instances].sort((a: any, b: any) =>
    b.published_at < a.published_at ? 1 : -1,
  );

  const dpConsumersByMonth = dpInstances
    .map(dpInstance => ({
      monthOfYear: DateTime.fromISO(dpInstance.published_at).toFormat(
        'MMM yyyy',
      ),
      value: dpInstance.inputPorts_aggregate.aggregate.count,
    }))
    // group by month of year
    .reduce((acc: any, obj: ValueByMonth) => {
      const key = obj.monthOfYear;
      if (!acc[key]) {
        acc[key] = obj.value;
        return acc;
      }

      acc[key] += obj.value;
      return acc;
    }, {});

  const startDate = dpInstances[0].published_at;
  if (!startDate) {
    return [];
  }

  return getMonthsBetweenDateAndNow(startDate).map((monthOfYear: string) => {
    if (dpConsumersByMonth[monthOfYear]) {
      const count = dpConsumersByMonth[monthOfYear];
      return {
        monthOfYear: monthOfYear,
        value: parseFloat(((count / dpInstances.length) * 100).toFixed(1)),
      } as ValueByMonth;
    }
    return {
      monthOfYear: monthOfYear,
      value: 0,
    };
  });
}

export const NetworkEffectKpiCard = (props: {
  animationsDuration?: number;
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { animationsDuration } = props;
  const { selectedTaxonomyRef, systemTypes } = useTaxonomySelection();
  const { priorityEnvironment } = useEnvironmentsContext();
  const { loading, error, data } = useQuery(NETWORK_EFFECT, {
    variables: {
      where: buildDashboardWhereQuery(extractTaxonomyUrn(selectedTaxonomyRef), [
        { environment: { name: { _eq: priorityEnvironment.name } } },
      ]),
    },
  });

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <ErrorPanel error={error} />;
  }

  const hasData = data.instances && data.instances.length > 0;

  return (
    <WbCard
      title="Network Effect"
      icon={
        <Tooltip
          title={`Percentage of production ${pluralize(
            getCardLabel(systemTypes),
          )} consuming data from other domains`}
        >
          <InfoOutlinedIcon color="primary" />
        </Tooltip>
      }
      cardStyle={{ height: '100%' }}
    >
      <WbCardContent>
        <div className={classes.chartContainer}>
          {!hasData && (
            <div className={classes.networkChartContainerEmpty}>
              No data available
            </div>
          )}
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={parseData(data)}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="monthOfYear" />
              <YAxis unit="%" />
              <ChartTooltip />
              <Line
                type="monotone"
                dataKey="value"
                stroke={theme.palette.charts[0]}
                activeDot={{ r: 8 }}
                animationDuration={animationsDuration}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </WbCardContent>
    </WbCard>
  );
};
