import React, {
  ReactNode,
  RefObject,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { Tooltip, TooltipProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useElementSize } from '../../hooks';
import { WbPopoverContextProvider } from './WbPopoverContext';
import { PartialPopper } from './types';

export type WbPopoverProps = Omit<
  TooltipProps,
  'arrow' | 'title' | 'classes'
> & {
  component: NonNullable<ReactNode>;
  responsive?: boolean;
};

const useStyles = makeStyles(
  theme => ({
    // override the default tooltip wrapper style
    tooltip: {
      fontSize: 'inherit',
      color: 'inherit',
      padding: 0,
      backgroundColor: theme.palette.white,
      borderRadius: theme.spacing(0.5),
      boxShadow: theme.shadows[1],
      maxWidth: 'none',
    },
  }),
  { name: 'WbPopover' },
);

export const ResponsiveContentWrapper = ({
  popperRef,
  children,
}: {
  popperRef: RefObject<PartialPopper>;
  children: ReactNode;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const size = useElementSize(contentRef, 50);

  // updates the popper instance in useLayoutEffect, to prevent the popper overflowing before updating the position
  useLayoutEffect(() => {
    popperRef.current?.update?.();
  }, [size, popperRef]);

  // Delays the container div expansion when the content changes size, so that it has the chance to update popper instance first
  return (
    <div
      style={{
        overflow: 'hidden',
        width: size.scrollWidth || undefined,
        height: size.scrollHeight || undefined,
      }}
    >
      <div ref={contentRef}>{children}</div>
    </div>
  );
};

/**
 * @param component The component to render inside the popover
 * @param responsive Use this when the content of the rendered component is dynamic and its size could change, the popover will try to recompute its position to fit every size change
 */
export const WbPopover: React.FC<WbPopoverProps> = ({
  component,
  className,
  PopperProps: popperProps,
  responsive,
  ...props
}) => {
  const ref = useRef(null);

  const classes = useStyles();
  const mergedClasses = useMemo(() => {
    const newClasses = structuredClone(classes);
    // add the provided className to the tooltip class
    newClasses.tooltip = clsx(className, newClasses.tooltip);
    return newClasses;
  }, [className, classes]);

  const content = component;

  return (
    <WbPopoverContextProvider popperRef={ref}>
      <Tooltip
        {...props}
        PopperProps={{ popperRef: ref, keepMounted: false, ...popperProps }}
        classes={mergedClasses}
        title={
          responsive ? (
            <ResponsiveContentWrapper popperRef={ref}>
              {content}
            </ResponsiveContentWrapper>
          ) : (
            content
          )
        }
      />
    </WbPopoverContextProvider>
  );
};
