import {
  CustomView,
  CustomViewComponent,
  CustomViewCtrls,
  CustomViewPages,
} from '@agilelab/plugin-wb-platform';
import React from 'react';
import { ComponentDetailsData, componentDetailsCtrl } from '../utils';

CustomViewPages.register({
  id: 'marketplace_data_contract_popover_overview',
  function: function Entity() {
    return (
      <CustomViewComponent type="container">
        <CustomViewComponent
          label="Version"
          value="{{version}}"
          type="string"
          colSpan="2"
        />
        <CustomViewComponent
          type="date"
          value="{{_system.published_at}}"
          label="Published Date"
          colSpan="2"
        />
        <CustomViewComponent type="hline" />
        <CustomViewComponent
          label="Owner"
          value="{{_system.owner_display_name}}"
          type="string"
          colSpan="2"
        />
        <CustomViewComponent
          label="Email"
          value="{{_system.descriptor.email}}"
          type="string"
          colSpan="2"
        />
        <CustomViewComponent
          type="hline"
          showWhen={{
            value: '{{ tags.length }}',
            notEquals: 0,
          }}
        />
        <CustomViewComponent
          showWhen={{
            value: '{{tags.length}}',
            notEquals: 0,
          }}
          label="Tags"
          type="tags"
          path="tags"
        />
      </CustomViewComponent>
    );
  },
});

export type DataContractPopoverOverviewProps = {
  data: ComponentDetailsData;
};

export const DataContractPopoverOverview: React.FC<
  DataContractPopoverOverviewProps
> = ({ data }) => {
  return (
    <CustomView
      id="marketplace_data_contract_popover_overview"
      typeId={data.componentInstance.descriptor.kind}
      templateId={data.componentInstance.descriptor.useCaseTemplateId}
      data={data}
    />
  );
};

CustomViewCtrls.register({
  id: 'marketplace_data_contract_popover_overview',
  function: componentDetailsCtrl,
});
