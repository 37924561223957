import { MarketplaceSearchResultEnriched } from '../../types';

export const adaptData = (row: MarketplaceSearchResultEnriched) => {
  return {
    ...row.document,
    type: row.document._resolvedType,
    publishedAt: row.document._computedInfo.publishedAt,
    id: row.document.id,
    // Fallbacks
    description: row.document.description ?? '',
    tags: row.document.tags ?? [],
    useCaseTemplateId: row.document.useCaseTemplateId ?? '',
    actions: '',
  };
};

export type Row = ReturnType<typeof adaptData>;
