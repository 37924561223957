import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useRef,
} from 'react';

type WbPopoverControlContextType = {
  subscribe: (cb: Function) => () => void;
  close: () => void;
};

const defaultWbPopoverControlContext = null;

export const WbPopoverControlContext =
  createContext<WbPopoverControlContextType | null>(
    defaultWbPopoverControlContext,
  );

export const useWbPopoverControlContext = () => {
  const contextValue = useContext(WbPopoverControlContext);

  if (!contextValue) {
    throw new Error(
      'useWbPopoverControlContext has to be used within <WbPopoverControlContext.Provider>',
    );
  }

  return contextValue;
};

export const WbPopoverControlContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const subscriptions = useRef(new Set<Function>());

  const subscribe = useCallback((cb: Function) => {
    subscriptions.current.add(cb);

    return () => {
      subscriptions.current.delete(cb);
    };
  }, []);

  const close = useCallback(() => {
    for (const sub of subscriptions.current) {
      sub();
      subscriptions.current.delete(sub);
    }
  }, []);
  return (
    <WbPopoverControlContext.Provider value={{ subscribe, close }}>
      {children}
    </WbPopoverControlContext.Provider>
  );
};
