import { Theme } from '@material-ui/core';

export const getTaxonomyColor = (
  taxonomyId: string,
  theme: Theme,
  fallbackColor?: string,
) =>
  theme.palette.taxonomies[taxonomyId] ??
  fallbackColor ??
  theme.palette.accent.light;

export const brighten = (hex: string, factor: number = 1.4): string => {
  // Convert the hexadecimal color to RGB components
  const r: number = parseInt(hex.substring(1, 3), 16);
  const g: number = parseInt(hex.substring(3, 5), 16);
  const b: number = parseInt(hex.substring(5, 7), 16);

  // Convert RGB to HSL
  const { h, s, l } = rgbToHsl(r, g, b);

  // Increase saturation and decrease lightness to get a brighter color
  const newS = Math.min(1, s * factor); // Increase saturation
  const newL = Math.max(0, l / factor); // Slightly reduce lightness

  const { r: newR, g: newG, b: newB } = hslToRgb(h, newS, newL);

  return `#${componentToHex(newR)}${componentToHex(newG)}${componentToHex(
    newB,
  )}`;
};

function rgbToHsl(
  r: number,
  g: number,
  b: number,
): { h: number; s: number; l: number } {
  const rNorm = r / 255;
  const gNorm = g / 255;
  const bNorm = b / 255;

  const max = Math.max(rNorm, gNorm, bNorm);
  const min = Math.min(rNorm, gNorm, bNorm);
  let h = 0;
  let s = 0;
  const l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case rNorm:
        h = (gNorm - bNorm) / d + (gNorm < bNorm ? 6 : 0);
        break;
      case gNorm:
        h = (bNorm - rNorm) / d + 2;
        break;
      case bNorm:
        h = (rNorm - gNorm) / d + 4;
        break;
      default:
        break;
    }
    h /= 6;
  }

  return { h, s, l };
}

function hslToRgb(
  h: number,
  s: number,
  l: number,
): { r: number; g: number; b: number } {
  let rVal: number;
  let gVal: number;
  let bVal: number;

  function hueToRgb(p: number, q: number, t: number): number {
    let tVal = t;
    if (tVal < 0) tVal += 1;
    if (tVal > 1) tVal -= 1;
    if (tVal < 1 / 6) return p + (q - p) * 6 * tVal;
    if (tVal < 1 / 2) return q;
    if (tVal < 2 / 3) return p + (q - p) * (2 / 3 - tVal) * 6;
    return p;
  }

  if (s === 0) {
    rVal = gVal = bVal = l; // Achromatic
  } else {
    const qValue: number = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const pValue: number = 2 * l - qValue;
    rVal = hueToRgb(pValue, qValue, h + 1 / 3);
    gVal = hueToRgb(pValue, qValue, h);
    bVal = hueToRgb(pValue, qValue, h - 1 / 3);
  }

  return {
    r: Math.round(rVal * 255),
    g: Math.round(gVal * 255),
    b: Math.round(bVal * 255),
  };
}

function componentToHex(c: number): string {
  const hex: string = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}
