import React from 'react';

export const SystemNodeHexagon = () => {
  return (
    <svg
      width="266.2"
      height="237.6"
      viewBox="0 0 242 216"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_255_38221)">
        <path
          d="M76.4975 12C70.7676 12 65.4704 15.0318 62.5909 19.9593L18.1787 95.9593C15.2738 100.93 15.2738 107.07 18.1787 112.041L62.5909 188.041C65.4704 192.968 70.7676 196 76.4975 196H165.503C171.232 196 176.53 192.968 179.409 188.041L223.821 112.041C226.726 107.07 226.726 100.93 223.821 95.9593L179.409 19.9593C176.53 15.0319 171.232 12 165.503 12H76.4975Z"
          fill="white"
        />
        <path
          d="M64.3177 20.9684C66.8368 16.6576 71.4749 14 76.4975 14H165.503C170.525 14 175.163 16.6576 177.682 20.9684L222.095 96.9684C224.635 101.316 224.635 106.684 222.095 111.032L177.682 187.032C175.163 191.342 170.525 194 165.503 194H76.4975C71.4749 194 66.8368 191.342 64.3177 187.032L19.9055 111.032C17.3648 106.684 17.3648 101.316 19.9055 96.9684L64.3177 20.9684Z"
          stroke="#7C4DFF"
          strokeWidth="4"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_255_38221"
          x="0"
          y="0"
          width="242"
          height="216"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.572549 0 0 0 0 0.572549 0 0 0 0 0.572549 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_255_38221"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_255_38221"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const SystemNodeHexagonEmpty = () => {
  return (
    <svg
      width="266.2"
      height="237.6"
      viewBox="0 0 242 216"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_208_2491)">
        <mask id="path-1-inside-1_208_2491" fill="white">
          <path d="M76.4975 12C70.7676 12 65.4704 15.0318 62.5909 19.9593L18.1787 95.9593C15.2738 100.93 15.2738 107.07 18.1787 112.041L62.5909 188.041C65.4704 192.968 70.7676 196 76.4975 196H165.503C171.232 196 176.53 192.968 179.409 188.041L223.821 112.041C226.726 107.07 226.726 100.93 223.821 95.9593L179.409 19.9593C176.53 15.0319 171.232 12 165.503 12H76.4975Z" />
        </mask>
        <path
          d="M76.4975 12C70.7676 12 65.4704 15.0318 62.5909 19.9593L18.1787 95.9593C15.2738 100.93 15.2738 107.07 18.1787 112.041L62.5909 188.041C65.4704 192.968 70.7676 196 76.4975 196H165.503C171.232 196 176.53 192.968 179.409 188.041L223.821 112.041C226.726 107.07 226.726 100.93 223.821 95.9593L179.409 19.9593C176.53 15.0319 171.232 12 165.503 12H76.4975Z"
          fill="white"
        />
        <path
          d="M76.4975 12C70.7676 12 65.4704 15.0318 62.5909 19.9593L18.1787 95.9593C15.2738 100.93 15.2738 107.07 18.1787 112.041L62.5909 188.041C65.4704 192.968 70.7676 196 76.4975 196H165.503C171.232 196 176.53 192.968 179.409 188.041L223.821 112.041C226.726 107.07 226.726 100.93 223.821 95.9593L179.409 19.9593C176.53 15.0319 171.232 12 165.503 12H76.4975Z"
          stroke="#7C4DFF"
          strokeWidth="4"
          strokeDasharray="4 4"
          mask="url(#path-1-inside-1_208_2491)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_208_2491"
          x="0"
          y="0"
          width="242"
          height="216"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.572549 0 0 0 0 0.572549 0 0 0 0 0.572549 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_208_2491"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_208_2491"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
