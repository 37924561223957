import { ReservedCatalogInfoFields } from './types';

export const SKELETON_FILE_DIRECTIVE = '%SKELETON';
export const PARAMETERS_FILE = 'parameters.yaml';

const systemReservedFields: ReservedCatalogInfoFields['system'] = {
  name: {
    path: 'metadata.name',
    skeletonParameter: 'readonly.__name__',
  },
  version: {
    path: 'spec.mesh.version',
    skeletonParameter: 'readonly.__version__',
  },
  instanceOf: {
    path: 'spec.instanceOf',
    skeletonParameter: 'readonly.__instanceOf__',
  },
};

const componentReservedFields: ReservedCatalogInfoFields['component'] = {
  name: { path: 'metadata.name', skeletonParameter: 'readonly.__name__' },
  system: { path: 'spec.system', skeletonParameter: 'readonly.__system__' },
  version: {
    path: 'spec.mesh.version',
    skeletonParameter: 'readonly.__version__',
  },
  instanceOf: {
    path: 'spec.instanceOf',
    skeletonParameter: 'readonly.__instanceOf__',
  },
  dependsOn: {
    path: 'spec.mesh.dependsOn',
    skeletonParameter: 'parameters.dependsOn',
  },
};

export const RESERVED_CATALOG_INFO_FIELDS: ReservedCatalogInfoFields = {
  system: systemReservedFields,
  component: componentReservedFields,
};
