import { z } from 'zod';
import { EvaluationResultResult } from '../evaluation-result';
import { Timing } from '../../enums';

export const ComputePolicyViolationsCountRequestSchema = z.object({
  resources: z.string().array().describe('Resource names'),
  environment: z.string().min(1),
  timing: z.nativeEnum(Timing).optional(),
});

export type ComputePolicyViolationsCountRequest = z.infer<
  typeof ComputePolicyViolationsCountRequestSchema
>;

export type PolicyViolationOutcome =
  | 'error'
  | 'warning'
  | 'info'
  | 'not_blocking_error';

export type PolicyViolationsCount = {
  items: {
    resource: string;
    violations: {
      outcome: PolicyViolationOutcome;
      count: number;
    }[];
  }[];
};

export type PolicyViolationsCountMap = Map<
  string,
  Map<PolicyViolationOutcome, number>
>;

export type GovernancePolicyFlags = {
  info: number;
  warning: number;
  error: number;
};

export const GetPolicyViolationsRequestSchema = z.object({
  resource: z.string().describe('Resource name'),
  environment: z.string(),
  policies: z.array(z.string()).optional(),
  timing: z.nativeEnum(Timing).optional(),
});

export type GetPolicyViolationsRequest = z.infer<
  typeof GetPolicyViolationsRequestSchema
>;

export type PolicyViolation = {
  resourceFilter?: string;
  resourceVariantId?: string;
  policyId: string;
  reportId: string;
  outcome: PolicyViolationOutcome;
  evaluationResultId: string;
  evaluationResult: EvaluationResultResult;
  evaluationTime: string;
};

export type PolicyViolations = {
  violations: PolicyViolation[];
};
