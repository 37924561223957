import {
  GovernancePolicyFlags,
  PolicyViolationsCountMap,
  violationsCountToGovernanceFlags,
} from '@agilelab/plugin-wb-governance-common';
import { Box, createStyles, makeStyles, Tooltip } from '@material-ui/core';
import FlagIcon from '@material-ui/icons/Flag';
import SyncProblemOutlinedIcon from '@material-ui/icons/SyncProblemOutlined';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { selectOnViolationSeverity } from '../../../utils/governanceUtils';

const useStyles = makeStyles(theme =>
  createStyles({
    iconBase: {
      // fix extra margin under the icon
      display: 'block',
    },
    infoIcon: {
      color: theme.palette.info.main,
    },
    warningIcon: {
      color: theme.palette.warning.main,
    },
    errorIcon: {
      color: theme.palette.error.main,
    },
    unavailableFlagsIcon: {
      color: theme.palette.softened.secondary,
    },
  }),
);

const getTooltip = (
  count: number,
  type: 'info' | 'warning' | 'error',
): string => {
  if (count === 1) return `${count} validation ${type}`;
  if (count > 1) return `${count} validation ${type}s`;
  return '';
};

function generateTooltipAndIcon(
  flags: GovernancePolicyFlags,
  classes: ReturnType<typeof useStyles>,
): { tooltipContent: ReactNode | undefined; iconClass: string | undefined } {
  return selectOnViolationSeverity(
    flags,
    {
      error: {
        tooltipContent: (
          <div style={{ whiteSpace: 'pre-line' }}>
            {getTooltip(flags.error, 'error')} {'\n'}
          </div>
        ),
        iconClass: clsx(classes.iconBase, classes.errorIcon),
      },
      warning: {
        tooltipContent: getTooltip(flags.warning, 'warning'),
        iconClass: clsx(classes.iconBase, classes.warningIcon),
      },
      info: {
        tooltipContent: getTooltip(flags.info, 'info'),
        iconClass: clsx(classes.iconBase, classes.infoIcon),
      },
    },
    { tooltipContent: undefined, iconClass: undefined },
  );
}

export type FlagBoxProps = {
  externalId: string;
  policyViolationsCountMap?: PolicyViolationsCountMap;
  error?: Error;
  loading?: boolean;
  iconStyle?: React.CSSProperties;
};

export const FlagBox = (props: FlagBoxProps) => {
  const { loading, error, policyViolationsCountMap: flagsMap } = props;
  const classes = useStyles();

  const { tooltipContent, iconClass } = generateTooltipAndIcon(
    violationsCountToGovernanceFlags(
      flagsMap?.get(props.externalId) ?? new Map(),
    ),
    classes,
  );

  if (loading) return <></>;

  const flagIconWithTooltip = tooltipContent && (
    <Tooltip title={tooltipContent}>
      <FlagIcon style={props.iconStyle} className={iconClass} />
    </Tooltip>
  );

  return (
    <Box>
      {error && (
        <Tooltip title="Flags are temporarily unavailable">
          <SyncProblemOutlinedIcon
            style={props.iconStyle}
            className={clsx(classes.iconBase, classes.unavailableFlagsIcon)}
          />
        </Tooltip>
      )}

      {flagIconWithTooltip}
    </Box>
  );
};
