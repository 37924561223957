/* eslint-disable no-cond-assign */
/* eslint-disable no-param-reassign */
import {
  ErrorInfo,
  GovernancePolicyFlags,
  PolicyViolationsCountMap,
} from '@agilelab/plugin-wb-governance-common';
import { ConfigApi, IdentityApi } from '@backstage/core-plugin-api';
import { GovernanceApi } from '../api';

const DEFAULT_WITTY_ENGINE_ENDPOINT = '/v1/evaluate';

type NamedAndVersioned = {
  name: string;
  version: string | number;
};

export const generateHeaderTitle = (
  entity: NamedAndVersioned | undefined,
  defaultValue: string,
  suffix: string = '',
) => {
  return entity ? `${entity.name} v${entity.version}${suffix}` : defaultValue;
};

const extractErrorMessage = (text: string) => {
  return text.replace(new RegExp(`(?:\\s)\\w*:\\d*:\\d*`, 'g'), '');
};

const extractErrorRow = (error: string, regx: RegExp) => {
  const errorRows: Array<number> = [];
  let item: RegExpExecArray | null;

  while ((item = regx.exec(error))) {
    errorRows.push(+item[1]);
  }
  return errorRows;
};

export const extractGovernanceErrorInfo = ({
  policyId,
  error,
  regxPolicy,
  regxDescriptor,
}: {
  policyId: string;
  error: string;
  regxPolicy?: RegExp;
  regxDescriptor?: RegExp;
}) => {
  if (!regxPolicy) {
    regxPolicy = new RegExp(`(?:\\s)${policyId}:(\\d*)`, 'g');
  }
  if (!regxDescriptor) {
    regxDescriptor = new RegExp(`(?:\\s)descriptor:(\\d*)`, 'g');
  }

  const errorInfo: ErrorInfo = {
    message: extractErrorMessage(error),
    policyErrorRows: extractErrorRow(error, regxPolicy),
    descriptorErrorRows: extractErrorRow(error, regxDescriptor),
  };

  return errorInfo;
};

export const debounceFunction = (fn: Function, ms = 500) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(args), ms);
  };
};

export function roundWithoutApprossimation(
  precision: number,
  n?: number,
): string {
  if (n !== null && n !== undefined) {
    const factor = Math.pow(10, precision);
    return String(Math.floor(n * factor) / factor);
  }
  return '-';
}

export function removeEndpointValidationUrl(
  url: string,
  config: ConfigApi,
): string {
  const agentEndpoint =
    config.getOptionalString('mesh.governance.agent.cgp.endpoint') ??
    DEFAULT_WITTY_ENGINE_ENDPOINT;
  let viewUrl = url;
  if (viewUrl?.endsWith(agentEndpoint))
    viewUrl = viewUrl.slice(0, -agentEndpoint.length);

  return viewUrl;
}

export function addEndpointValidationUrl(
  url: string,
  config: ConfigApi,
): string {
  const agentEndpoint =
    config.getOptionalString('mesh.governance.agent.cgp.endpoint') ??
    DEFAULT_WITTY_ENGINE_ENDPOINT;
  let viewUrl = url;
  if (!viewUrl?.endsWith(agentEndpoint)) viewUrl = viewUrl + agentEndpoint;

  return viewUrl;
}

type DataContractEvaluationIdentifier = {
  policyId: string;
  externalId: string;
  environment: string;
};

/**
 * Given an array of resources, computes their policy violations count in parallel requests (maxItemsPerRequest elements per request) and merges them into a single map (Used to prevent sending payloads too big when requesting data for a lot of items)
 */
export async function batchComputePolicyViolationsCount(
  identifiers: string[],
  governanceApi: GovernanceApi,
  identityApi: IdentityApi,
  environment: string,
  maxItemsPerRequest = 100,
) {
  const chunks: string[][] = [];

  identifiers.forEach(id => {
    let last = chunks.at(-1);
    if (!last || last.length >= maxItemsPerRequest) {
      last = [];
      chunks.push(last);
    }
    last.push(id);
  });

  const token = (await identityApi.getCredentials()).token;

  const counts = await Promise.all(
    chunks.map(c =>
      governanceApi.computePolicyViolationsCount(
        {
          environment: environment,
          // use a set to make sure each id is distinct
          resources: Array.from(new Set(c)),
        },
        { token },
      ),
    ),
  );

  return counts.reduce<PolicyViolationsCountMap>((prev, curr) => {
    for (const [key, val] of curr.entries()) {
      prev.set(key, val);
    }

    return prev;
  }, new Map());
}

/**
 * Given an array containing for each element the metadata required to fetch the evaluation status of a data contract,
 * fetches them in parallel requests (maxItemsPerRequest elements per request) and merges them into a single array. (Used to prevent sending payloads too big when requesting data for a lot of items)
 */
export async function batchGetDataContractsEvaluationStatus(
  identifiers: DataContractEvaluationIdentifier[],
  governanceApi: GovernanceApi,
  identityApi: IdentityApi,
  maxItemsPerRequest = 100,
) {
  const chunks: DataContractEvaluationIdentifier[][] = [];

  identifiers.forEach(id => {
    let last = chunks.at(-1);
    if (!last || last.length >= maxItemsPerRequest) {
      last = [];
      chunks.push(last);
    }
    last.push(id);
  });

  const token = (await identityApi.getCredentials()).token;

  const evaluations = await Promise.all(
    chunks.map(c =>
      governanceApi.findResourceEvaluationStatus(
        {
          filters: {
            statusFilter: {
              identifierIn: c.map(d => ({
                governanceEntityId: d.policyId,
                resourceIdentifier: {
                  resourceExternalId: d.externalId,
                  environment: d.environment,
                },
              })),
            },
          },
          resultOptions: {},
        },
        { token },
      ),
    ),
  );

  return evaluations.flatMap(e => e.data);
}

export const selectOnViolationSeverity = <T>(
  counts: GovernancePolicyFlags,
  options: { [key in keyof GovernancePolicyFlags]: T },
  fallback: T,
) => {
  if (counts.error > 0) {
    return options.error;
  } else if (counts.warning > 0) {
    return options.warning;
  } else if (counts.info > 0) {
    return options.info;
  }
  return fallback;
};
