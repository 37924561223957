import {
  RELATION_CHILD_OF,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_MEMBER,
  RELATION_HAS_PART,
  RELATION_MEMBER_OF,
  RELATION_OWNED_BY,
  RELATION_OWNER_OF,
  RELATION_PARENT_OF,
  RELATION_PART_OF,
} from '@backstage/catalog-model';

export const EXTENDS = 'wb-extends';
export const EXTENDED_BY = 'wb-extendedBy';

export const PART_OF = 'wb-partOf';
export const HAS_PART = 'wb-hasPart';

export const INSTANCE_OF = 'wb-instanceOf';
export const CLASS_OF = 'wb-classOf';

export const GENERATES = 'wb-generates';
export const GENERATED_BY = 'wb-generatedBy';

export const LINKED_TO_GROUP = 'wb-linkedToGroup';
export const GROUP_LINKED_TO = 'wb-groupLinkedTo';

export const BELONGS_TO = 'wb-belongsTo';
export const HAS_CHILD = 'wb-hasChild';

export const OWNED_BY = 'wb-ownedBy';
export const OWNER_OF = 'wb-ownerOf';

export const READS_FROM = 'wb-readsFrom';
export const READ_BY = 'wb-readBy';

export const DEPENDS_ON = 'wb-dependsOn';
export const HAS_DEPENDENCY = 'wb-hasDependency';

export type RelationPairs = [string, string][];

export const WITBOOST_RELATION_PAIRS: RelationPairs = [
  // Backstage relations
  [RELATION_OWNER_OF, RELATION_OWNED_BY],
  [RELATION_HAS_PART, RELATION_PART_OF],
  [RELATION_PARENT_OF, RELATION_CHILD_OF],
  [RELATION_HAS_MEMBER, RELATION_MEMBER_OF],
  [RELATION_DEPENDS_ON, RELATION_DEPENDENCY_OF],
  // Witboost relations
  [EXTENDS, EXTENDED_BY],
  [PART_OF, HAS_PART],
  [INSTANCE_OF, CLASS_OF],
  [GENERATES, GENERATED_BY],
  [LINKED_TO_GROUP, GROUP_LINKED_TO],
  [BELONGS_TO, HAS_CHILD],
  [OWNED_BY, OWNER_OF],
  [READS_FROM, READ_BY],
  [DEPENDS_ON, HAS_DEPENDENCY],
];
