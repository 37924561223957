import React from 'react';
import { TableCellProps, WbTagsTableCell } from '@agilelab/plugin-wb-platform';
import { DateCell, DescriptionCell, NameCell } from './SearchResultsTableCells';
import { TypeBadge } from '../../../Entity/TypeBadge';
import { Row } from './utils';
import { SearchFlagBox } from '../../components/SearchFlagBox';
import { SearchSystemActionsMenu } from '../../components/SearchSystemActionsMenu';

export const columns: Array<TableCellProps<Row>> = [
  {
    field: 'title',
    headerName: 'Name',
    fieldRender: result => <NameCell searchResult={result} />,
  },
  {
    field: '_resolvedType',
    headerName: 'Type',
    fieldRender: result => (
      <TypeBadge
        typeDisplayName={result._resolvedType}
        kind={result._computedInfo.kind}
      />
    ),
  },
  {
    field: 'description',
    headerName: 'Description',
    fieldRender: result => (
      <DescriptionCell title={result.description}>
        {result.description}
      </DescriptionCell>
    ),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    fieldRender: result => (
      <WbTagsTableCell maxWidth={200} tags={result.tags || []} />
    ),
  },
  {
    field: 'publishedAt',
    headerName: 'Last Published',
    cellProps: {
      width: '190px',
    },
    fieldRender: result => {
      return <DateCell date={result.publishedAt} />;
    },
  },
  {
    // put 'key' as there is no flag field in Row but WbTable forces you to provide a field
    field: 'key' as keyof Row,
    headerName: 'Flag',
    fieldRender: result => {
      return (
        <SearchFlagBox iconStyle={{ fontSize: 20 }} externalId={result.id} />
      );
    },
  },
  {
    field: 'actions',
    headerName: '',
    cellProps: {
      width: '30px',
    },
    fieldRender: result => {
      return (
        result._computedInfo.kind === 'system' && (
          <SearchSystemActionsMenu
            dataProductId={result.documentId}
            size="small"
          />
        )
      );
    },
  },
];
