import { WitboostComponent } from '@agilelab/plugin-wb-builder-common';
import {
  AutoFixHighIcon,
  WbCardActionButton,
} from '@agilelab/plugin-wb-platform';
import { NO_REVERSE_PROVISIONING_TEMPLATE_FOUND_ERROR } from '@agilelab/plugin-wb-platform-common';
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { Box, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAsync, useMountEffect } from '@react-hookz/web';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { useEditorPageContext } from '../context/useEditorPageContext';

const useStyles = makeStyles(theme => ({
  importButton: {
    marginRight: '5px',
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  optionButton: {
    color: theme.palette.secondary.main,
    transition: 'color 0.3s ease',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'white',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: '2px',
    right: '2px',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));

const ImportButton = ({ entity }: { entity: Entity }) => {
  const classes = useStyles();
  const catalogApi = useApi(catalogApiRef);
  const navigate = useNavigate();
  const { entitiesAndBranches } = useEditorPageContext();
  const [currentBranch, setCurrentBranch] = useState<string | undefined>();

  useEffect(() => {
    if (entity) {
      const entityRef = stringifyEntityRef({
        name: entity?.metadata?.name || '',
        kind: entity?.kind,
        namespace: entity?.metadata?.namespace || '',
      });

      setCurrentBranch(entitiesAndBranches[entityRef]);
    }
  }, [entitiesAndBranches, entity]);

  const href = useMemo(() => {
    return {
      pathname: `/scaffolder/${encodeURIComponent(
        entity.metadata.namespace ?? 'default',
      )}/${encodeURIComponent(
        entity.kind.toLocaleLowerCase(),
      )}/${encodeURIComponent(entity.metadata.name)}/reverse-provisioning`,
      search: createSearchParams(
        currentBranch
          ? {
              targetBranch: currentBranch,
            }
          : undefined,
      ).toString(),
    };
  }, [currentBranch, entity]);

  const [state, actions] = useAsync(async () => {
    const { items } = await catalogApi.getEntities({
      filter: {
        kind: 'ReverseProvisioningTemplate',
        'spec.useCaseTemplateId': (entity as WitboostComponent).spec.mesh
          .useCaseTemplateId,
      },
      limit: 1,
    });
    return items.length === 1;
  });

  useMountEffect(actions.execute);

  return (
    <Box>
      <Tooltip
        title={
          state.status !== 'success' || !state.result
            ? NO_REVERSE_PROVISIONING_TEMPLATE_FOUND_ERROR
            : ''
        }
      >
        <span>
          <WbCardActionButton
            label="Import"
            disableRipple
            startIcon={<AutoFixHighIcon />}
            onClick={_ => navigate(href)}
            disabled={state.status !== 'success' || !state.result}
            size="small"
            color="primary"
            variant="outlined"
            className={classes.importButton}
            style={{ textDecoration: 'none' }}
          />
        </span>
      </Tooltip>
    </Box>
  );
};

export default ImportButton;
