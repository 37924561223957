import { TotalDataProductInstancesSupervision } from '@agilelab/plugin-wb-marketplace-common';
import { notificationApiRef } from '@agilelab/plugin-wb-notification';
import { Notification } from '@agilelab/plugin-wb-notification-common';
import { WbCard, WbCardContent } from '@agilelab/plugin-wb-platform';
import { useTaxonomySelection } from '@agilelab/plugin-wb-practice-shaper';
import { useQuery } from '@apollo/client';
import { ErrorPanel, Progress } from '@backstage/core-components';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import {
  Theme,
  Tooltip,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { DateTime } from 'luxon';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';
import {
  CartesianGrid,
  Tooltip as ChartTooltip,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { GET_SYSTEM_TOTAL, ValueByMonth } from '../../graphql';
import { extractTaxonomyUrn, getMonthsBetweenDateAndNow } from '../../utils';
import { buildDashboardWhereQuery, getCardLabel } from './utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartContainer: {
      position: 'relative',
      width: '100%',
      height: '320px',
    },
    questionsChartContainerEmpty: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -100%)',
      padding: '12px 16px',
      borderRadius: '4px',
      background: theme.palette.grey[300],
      color: 'black',
    },
  }),
);

function parseData(
  questionsData: Notification[],
  data: TotalDataProductInstancesSupervision | undefined,
): ValueByMonth[] {
  if (
    !data ||
    !questionsData ||
    !data.instance_aggregate.aggregate.count ||
    data.instance_aggregate.aggregate.count === 0 ||
    questionsData.length === 0
  ) {
    return [];
  }

  const totalDataProductsInstancesCount =
    data.instance_aggregate.aggregate.count;
  const questions = questionsData.sort((a, b) =>
    b.created_at < a.created_at ? 1 : -1,
  );

  const questionsByMonth = questions
    .map(
      question =>
        ({
          monthOfYear: DateTime.fromISO(question.created_at).toFormat(
            'MMM yyyy',
          ),
          value: 1,
        } as ValueByMonth),
    )
    // group by month of year
    .reduce((acc: any, obj: ValueByMonth) => {
      const key = obj.monthOfYear;
      if (!acc[key]) {
        acc[key] = 0;
      }

      acc[key] += 1;
      return acc;
    }, {});

  const startDate = questions.at(0)?.created_at || undefined;
  if (!startDate) {
    return [];
  }

  return getMonthsBetweenDateAndNow(startDate).map((monthOfYear: string) => {
    if (questionsByMonth[monthOfYear]) {
      const count = questionsByMonth[monthOfYear];
      return {
        monthOfYear: monthOfYear,
        value: parseFloat(
          ((count / totalDataProductsInstancesCount) * 100).toFixed(1),
        ),
      } as ValueByMonth;
    }
    return {
      monthOfYear: monthOfYear,
      value: 0,
    };
  });
}

export const AverageQuestionsKpiCard = (props: {
  animationsDuration?: number;
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { selectedTaxonomyRef, systemTypes } = useTaxonomySelection();
  const { animationsDuration } = props;
  const { data, loading, error } =
    useQuery<TotalDataProductInstancesSupervision>(GET_SYSTEM_TOTAL, {
      variables: {
        where: buildDashboardWhereQuery(
          extractTaxonomyUrn(selectedTaxonomyRef),
        ),
      },
    });
  const notificationCatalog = useApi(notificationApiRef);
  const identityApi = useApi(identityApiRef);

  const {
    value: questions,
    error: questionsError,
    loading: questionsLoading,
  } = useAsync(async () => {
    return notificationCatalog.getNotificationQuestions(
      await identityApi.getCredentials(),
    );
  });

  if (loading || questionsLoading) {
    return <Progress />;
  } else if (error || questionsError) {
    return <ErrorPanel error={error || questionsError!} />;
  }

  const hasData = questions && questions.total > 0;

  return (
    <WbCard
      title="Average Questions Asked"
      icon={
        <Tooltip
          title={`Percentage of questions for each ${getCardLabel(
            systemTypes,
          )}`}
        >
          <InfoOutlinedIcon color="primary" />
        </Tooltip>
      }
      cardStyle={{ height: '100%' }}
    >
      <WbCardContent>
        <div className={classes.chartContainer}>
          {!hasData && (
            <div className={classes.questionsChartContainerEmpty}>
              No data available
            </div>
          )}
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={parseData(questions?.notifications!, data)}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="monthOfYear" />
              <YAxis unit="%" />
              <ChartTooltip />
              <Line
                type="monotone"
                dataKey="value"
                stroke={theme.palette.charts[0]}
                activeDot={{ r: 8 }}
                animationDuration={animationsDuration}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </WbCardContent>
    </WbCard>
  );
};
