import {
  Entity,
  entityEnvelopeSchemaValidator,
  EntityMeta,
} from '@backstage/catalog-model';
import { generateAjvJsonSchemaValidator } from '../utils';
import schema from '../schema/WitboostComponent.v1alpha1.schema.json';

export type WitboostSubcomponent = Record<string, any> & {
  name: string;
  infrastructureTemplateId: string;
  consumable: boolean;
  kind: string;
  shoppable?: boolean;
  id: string;
};

export interface WitboostComponent extends Entity {
  apiVersion:
    | 'backstage.io/v1alpha1'
    | 'backstage.io/v1beta1'
    | 'witboost.com/v1';
  kind: 'Component';
  metadata: EntityMeta & {
    classDetails?: { pluralizedDisplayName?: string; displayName?: string };
  };
  spec: {
    domain?: string;
    instanceOf?: string;
    type: string;
    lifecycle: string;
    owner: string;
    subcomponentOf?: string;
    components?: WitboostSubcomponent[];
    providesApis?: string[];
    consumesApis?: string[];
    dependsOn?: string[];
    system?: string;
    mesh: {
      name: string;
      version: string;
      readsFrom?: string[];
      logicallyReadsFrom?: string[];
      shoppable?: boolean;
      consumable?: boolean;
      useCaseTemplateId: string;
      infrastructureTemplateId: string;
      dataProductOwner?: string;
      projectOwner?: string;
    } & Record<string, any>;
  } & Record<string, any>;
  witboost?: {
    parameters?: Record<string, any>;
  };
}

export const applyWitboostComponent =
  entityEnvelopeSchemaValidator<WitboostComponent>(schema);

export const witboostComponentV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);

export function isConsumable(component: WitboostComponent): boolean {
  return component.spec.mesh.__consumable ?? !!component.spec.mesh.consumable;
}
