import { gql } from '@apollo/client/core';

export const SYSTEM_FRAGMENT = gql`
  fragment SystemFragment on marketplace_instances {
    __typename
    id
    version
    display_name
    external_id
    kind
    type
    owner_display_name
    taxonomy {
      name
      external_id
    }
    domain: relations(where: { name: { _eq: "partOfDomain" } }) {
      data: instanceByTargetInstanceId {
        name
        external_id
      }
    }
    consumedDcs: relations(where: $consumedWhere) {
      data: instanceByTargetInstanceId {
        id
        parentDc: relations(
          where: {
            name: { _eq: "partOfComponent" }
            instanceByTargetInstanceId: {
              data_contract: { id: { _is_null: false } }
            }
          }
        ) {
          data: instanceByTargetInstanceId {
            id
          }
        }
      }
    }
    ownedDcs: relationsByTargetInstanceId(
      where: {
        name: { _eq: "partOfSystem" }
        instance: {
          _or: [
            { data_contract: { id: { _is_null: false } } }
            {
              relationsByTargetInstanceId: {
                name: { _eq: "partOfComponent" }
                instance: { data_contract: { id: { _is_null: false } } }
              }
            }
          ]
        }
      }
    ) {
      data: instance {
        id
        subDcs: relationsByTargetInstanceId(
          where: {
            name: { _eq: "partOfComponent" }
            instance: { data_contract: { id: { _is_null: false } } }
          }
        ) {
          data: instance {
            id
          }
        }
      }
    }
  }
`;

export const DC_FRAGMENT = gql`
  fragment DcFragment on marketplace_instances {
    __typename
    readers: relationsByTargetInstanceId(where: $readersWhere) {
      data: instance {
        ...SystemFragment
      }
    }
    subcomponents: relationsByTargetInstanceId(
      where: $consumedSubcomponentsWhere
    ) {
      instance {
        readers: relationsByTargetInstanceId(where: $readersWhere) {
          data: instance {
            ...SystemFragment
          }
        }
      }
    }
    dataContract: data_contract {
      policy_id
    }
    environment {
      name
    }
    id
    external_id
    display_name

    dcIngestionMode: descriptor(path: $ingestionModePath)
    dcOnBrokenContract: descriptor(path: $onBrokenContractPath)
    dcDescription: descriptor(path: $descriptionPath)

    version

    parentComponent: relations(where: { name: { _eq: "partOfComponent" } }) {
      data: instanceByTargetInstanceId {
        external_id
        system: relations(where: { name: { _eq: "partOfSystem" } }) {
          data: instanceByTargetInstanceId {
            ...SystemFragment
          }
        }
      }
    }
    parentSystem: relations(where: { name: { _eq: "partOfSystem" } }) {
      data: instanceByTargetInstanceId {
        ...SystemFragment
      }
    }
  }
`;

export const buildInstancesIdsWhere = (ids: number[]) => {
  return { id: { _in: ids } };
};

export const RESOLVE_DATA_CONTRACTS = gql`
  ${DC_FRAGMENT}
  ${SYSTEM_FRAGMENT}
  query ResolveDataContracts(
    $instancesIdsWhere: marketplace_instances_bool_exp
    $readersWhere: marketplace_relations_bool_exp
    $consumedSubcomponentsWhere: marketplace_relations_bool_exp
    $ingestionModePath: String
    $onBrokenContractPath: String
    $consumedWhere: marketplace_relations_bool_exp
    $descriptionPath: String
  ) {
    dcs: marketplace_instances(where: $instancesIdsWhere) {
      ...DcFragment
    }
  }
`;

export const RESOLVE_SYSTEMS = gql`
  ${SYSTEM_FRAGMENT}
  query ResolveSystems(
    $instancesIdsWhere: marketplace_instances_bool_exp
    $consumedWhere: marketplace_relations_bool_exp
  ) {
    systems: marketplace_instances(where: $instancesIdsWhere) {
      ...SystemFragment
    }
  }
`;

const buildComponentInstanceTaxonomyWhere = (taxonomy: string) => {
  const taxonomyFilter = {
    taxonomy: { external_id: { _eq: taxonomy } },
  };

  return {
    relations: {
      // two possible cases to match the taxonomy filter (1 must be true)
      _or: [
        {
          _and: {
            // in case the instance is a component, its parent system must match the taxonomy filter
            name: { _eq: 'partOfSystem' },
            instanceByTargetInstanceId: taxonomyFilter,
          },
        },
        {
          _and: {
            // in case the  instance is a subcomponent, the system whose parent component is part of must match the taxonomy filter
            name: { _eq: 'partOfComponent' },
            instanceByTargetInstanceId: {
              relations: {
                name: { _eq: 'partOfSystem' },
                instanceByTargetInstanceId: taxonomyFilter,
              },
            },
          },
        },
      ],
    },
  };
};

export const getGraphDataContractsBuildWhere = (
  env: string,
  taxonomy?: string,
  excludeNotConsumed = false,
) => {
  const where: Record<string, any> = {
    _and: [
      { environment: { name: { _eq: env } } },
      { data_contract: { id: { _is_null: false } } },
    ],
  };

  // if true, include only data contracts which are directly consumed, or whose subcomponents are consumed
  if (excludeNotConsumed)
    where._and.push({
      relationsByTargetInstanceId: {
        _or: [
          // directly consumed
          {
            name: { _eq: 'readsFrom' },
          },
          // or subcomponents are consumed
          {
            name: { _eq: 'partOfComponent' },
            instance: {
              relationsByTargetInstanceId: {
                name: {
                  _eq: 'readsFrom',
                },
              },
            },
          },
        ],
      },
    });

  if (taxonomy) where._and.push(buildComponentInstanceTaxonomyWhere(taxonomy));

  return where;
};

export const getConsumedBuildWhere = (taxonomy?: string) => {
  const where: Record<string, any> = {
    _and: [
      {
        name: { _eq: 'readsFrom' },
        instanceByTargetInstanceId: {
          _or: [
            {
              data_contract: { id: { _is_null: false } },
              ...(taxonomy
                ? buildComponentInstanceTaxonomyWhere(taxonomy)
                : {}),
            },
            {
              relations: {
                name: { _eq: 'partOfComponent' },
                instanceByTargetInstanceId: {
                  data_contract: { id: { _is_null: false } },
                  ...(taxonomy
                    ? buildComponentInstanceTaxonomyWhere(taxonomy)
                    : {}),
                },
              },
            },
          ],
        },
      },
    ],
  };

  return where;
};

export const getReadersBuildWhere = (taxonomy?: string) => {
  const taxonomyFilter = {
    taxonomy: { external_id: { _eq: taxonomy } },
  };

  const where: Record<string, any> = {
    _and: [{ name: { _eq: 'readsFrom' } }],
  };

  if (taxonomy) where._and.push({ instance: taxonomyFilter });

  return where;
};

export const getConsumedSubcomponentsBuildWhere = (taxonomy?: string) => {
  const where: Record<string, any> = {
    _and: [
      {
        name: { _eq: 'partOfComponent' },
        instance: {
          relationsByTargetInstanceId: getReadersBuildWhere(taxonomy),
        },
      },
    ],
  };

  return where;
};

// used for the data contracts overview poc
export const GET_DATA_CONTRACTS_GRAPH = gql`
  ${DC_FRAGMENT}
  ${SYSTEM_FRAGMENT}
  query GetDataContractGraph(
    $dataContractsWhere: marketplace_instances_bool_exp
    $readersWhere: marketplace_relations_bool_exp
    $consumedSubcomponentsWhere: marketplace_relations_bool_exp
    $ingestionModePath: String
    $onBrokenContractPath: String
    $descriptionPath: String
    $consumedWhere: marketplace_relations_bool_exp
  ) {
    marketplace_instances(where: $dataContractsWhere) {
      ...DcFragment
    }
  }
`;
