import { ApolloError, useQuery } from '@apollo/client';
import { ErrorPanel } from '@backstage/core-components';
import { Box, useTheme } from '@material-ui/core';
import React from 'react';
import { WbCardContent, WbNoData, WbCard } from '@agilelab/plugin-wb-platform';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { COUNT_SYSTEMS_GROUP_BY_ENV } from '../../graphql';
import { MarketplaceDpQuery } from './types/marketplace-dp';
import { useTaxonomySelection } from '@agilelab/plugin-wb-practice-shaper';
import { buildDashboardWhereQuery } from './utils';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { ChartDetail, OverviewComponent } from './OverviewComponent';
import { Skeleton } from '@material-ui/lab';
import { extractTaxonomyUrn } from '../../utils';

type EnvironmentCount = {
  env: string;
  count: number;
  total: number;
};

function extractChartDetails(
  data: EnvironmentCount[],
  colors: string[],
): ChartDetail[] {
  return data.map((datum, index) => ({
    label: datum.env,
    value: datum.count,
    color: colors[index % colors.length],
    percentage: Math.round((datum.count / datum.total) * 100),
  }));
}

function parseSummaryData(data: MarketplaceDpQuery): EnvironmentCount[] {
  return data.marketplace_environments.map(aggregate => {
    return {
      env: snakeCaseToTitleCase(aggregate?.name),
      count: aggregate?.instances_aggregate?.aggregate?.count || 0,
      total: data.marketplace_instances_aggregate.aggregate.count,
    };
  });
}

function parseMarketplaceData(data: MarketplaceDpQuery) {
  return data.marketplace_environments.map(env => ({
    name: env.name,
    value: env.instances_aggregate.aggregate.count,
  }));
}

function isEveryEnvironmentEmpty(data: MarketplaceDpQuery): boolean {
  return data.marketplace_environments.every(
    env => env.instances_aggregate.aggregate.count === 0,
  );
}

export const SummaryKpiCard = (props: { animationsDuration?: number }) => {
  const theme = useTheme();
  const { animationsDuration } = props;
  const chartColors = theme.palette.charts;
  const { selectedTaxonomyRef } = useTaxonomySelection();
  const {
    loading,
    error,
    data,
  }: {
    loading: boolean;
    error?: ApolloError;
    data?: MarketplaceDpQuery;
  } = useQuery(COUNT_SYSTEMS_GROUP_BY_ENV, {
    variables: {
      where: buildDashboardWhereQuery(extractTaxonomyUrn(selectedTaxonomyRef)),
    },
  });
  return (
    <WbCard title="Environments Distribution" cardStyle={{ height: '100%' }}>
      <WbCardContent
        style={{ height: '425px', display: 'flex', flexDirection: 'column' }}
      >
        {!!error && <ErrorPanel error={error} />}
        {!!loading && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2),
            }}
          >
            <Skeleton
              animation="wave"
              variant="rect"
              width="35%"
              height="16px"
            />
            <Skeleton
              animation="wave"
              variant="rect"
              width="35%"
              height="16px"
            />
            <Skeleton
              animation="wave"
              variant="circle"
              width="200px"
              height="200px"
              style={{ alignSelf: 'center' }}
            />
          </Box>
        )}
        {!!data && isEveryEnvironmentEmpty(data) && (
          <WbNoData
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
            }}
          />
        )}
        {!!data && !isEveryEnvironmentEmpty(data) && (
          <>
            <ResponsiveContainer height={200} width="100%">
              <PieChart>
                <Pie
                  data={parseMarketplaceData(data)}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  animationDuration={animationsDuration}
                >
                  {parseMarketplaceData(data).map((_entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={chartColors[index % chartColors.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
            <OverviewComponent
              chartDetails={extractChartDetails(
                parseSummaryData(data),
                chartColors as string[],
              )}
            />
          </>
        )}
      </WbCardContent>
    </WbCard>
  );
};
