import { Config } from '@backstage/config';
import {
  ComponentValidationRequest,
  GetComponentTypesRequest,
  GetComponentTypesResponse,
  GetComponentVariantResponse,
  GetCreationTemplatesByGeneratedTypeRequest,
  GetCreationTemplatesByGeneratedTypeResponse,
  GetDomainTypesRequest,
  GetDomainTypesResponse,
  GetResourceTypesRequest,
  GetResourceTypesResponse,
  GetSystemTypesRequest,
  GetSystemTypesResponse,
  GetTaxonomiesRequest,
  GetTaxonomiesResponse,
  IsHeadlessComponentRequest,
  PracticeShaperApi,
  RequestOptions,
  ResolveComponentTypeOfRequest,
  ResolveCreationTemplateGeneratedTypeRequest,
  ResolveCreationTemplateGeneratedTypeResponse,
  ResolveTaxonomyDomainTypesRequest,
  ResolveTaxonomyDomainTypesResponse,
  ResolveSystemTypeOfRequest,
  SystemValidationRequest,
  ResolveDomainTypeOfRequest,
} from './api';
import {
  SystemType,
  ComponentType,
  DomainType,
} from '@agilelab/plugin-wb-builder-common';
import {
  encodeQueryParams,
  handleFailedResponse,
} from '@agilelab/plugin-wb-platform-common';
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import crossFetch from 'cross-fetch';

/**
 * A frontend and backend compatible client for communicating with the Practice Shaper
 * service.
 *
 * @public
 */
export class PracticeShaperClient implements PracticeShaperApi {
  // private readonly config: Config;
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;
  private readonly baseUrlPromise: Promise<string>;

  constructor(options: {
    config: Config;
    discoveryApi: DiscoveryApi;
    fetchApi?: { fetch: typeof fetch };
  }) {
    // this.config = options.config;
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch: crossFetch };
    this.baseUrlPromise = this.discoveryApi.getBaseUrl('practice-shaper');
  }

  async validateSystem(
    request: SystemValidationRequest,
    options?: RequestOptions,
  ): Promise<void> {
    const baseUrl = await this.baseUrlPromise;
    const response = await this.fetchApi.fetch(`${baseUrl}/system/validation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
      },
      body: JSON.stringify(request),
    });

    await handleFailedResponse(response);
  }

  async validateComponent(
    request: ComponentValidationRequest,
    options?: RequestOptions,
  ): Promise<void> {
    const baseUrl = await this.baseUrlPromise;
    const response = await this.fetchApi.fetch(
      `${baseUrl}/component/validation`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
        body: JSON.stringify(request),
      },
    );

    await handleFailedResponse(response);
  }

  async isMaintenanceActive(options?: RequestOptions): Promise<boolean> {
    const baseUrl = await this.baseUrlPromise;
    const response = await this.fetchApi.fetch(`${baseUrl}/maintenance`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
      },
    });
    await handleFailedResponse(response);
    return response.json();
  }

  async getTaxonomies(
    request: GetTaxonomiesRequest,
    options?: RequestOptions,
  ): Promise<GetTaxonomiesResponse> {
    const baseUrl = await this.baseUrlPromise;
    const queryParams = {
      enabled: request.filter?.enabled,
    };
    const response = await this.fetchApi.fetch(
      `${baseUrl}/taxonomies${encodeQueryParams(queryParams)}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
      },
    );
    await handleFailedResponse(response);
    return response.json();
  }

  async getDomainTypes(
    request: GetDomainTypesRequest,
    options?: RequestOptions,
  ): Promise<GetDomainTypesResponse> {
    const baseUrl = await this.baseUrlPromise;
    const queryParams = {
      ...request.filter,
    };
    const response = await this.fetchApi.fetch(
      `${baseUrl}/domain-types${encodeQueryParams(queryParams)}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
      },
    );
    await handleFailedResponse(response);
    return response.json();
  }

  async getSystemTypes(
    request: GetSystemTypesRequest,
    options?: RequestOptions,
  ): Promise<GetSystemTypesResponse> {
    const baseUrl = await this.baseUrlPromise;
    const queryParams = {
      resourceTypeId: request.filter?.resourceTypeId,
      taxonomyRef: request.filter?.taxonomyRef,
    };
    const response = await this.fetchApi.fetch(
      `${baseUrl}/system-types${encodeQueryParams(queryParams)}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
      },
    );
    await handleFailedResponse(response);
    return response.json();
  }

  async getComponentTypes(
    request: GetComponentTypesRequest,
    options?: RequestOptions,
  ): Promise<GetComponentTypesResponse> {
    const baseUrl = await this.baseUrlPromise;
    const queryParams = {
      ...request.filter,
    };
    const response = await this.fetchApi.fetch(
      `${baseUrl}/component-types${encodeQueryParams(queryParams)}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
      },
    );
    await handleFailedResponse(response);
    return response.json();
  }

  async getCreationTemplatesByGeneratedType(
    request: GetCreationTemplatesByGeneratedTypeRequest,
    options?: RequestOptions | undefined,
  ): Promise<GetCreationTemplatesByGeneratedTypeResponse> {
    const baseUrl = await this.baseUrlPromise;
    const response = await this.fetchApi.fetch(
      `${baseUrl}/creation-templates${encodeQueryParams(request)}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
      },
    );
    await handleFailedResponse(response);
    return response.json();
  }

  async resolveCreationTemplateGeneratedType(
    request: ResolveCreationTemplateGeneratedTypeRequest,
    options?: RequestOptions,
  ): Promise<ResolveCreationTemplateGeneratedTypeResponse | undefined> {
    const baseUrl = await this.baseUrlPromise;
    const { name, namespace = 'default' } = request.templateRef;
    const response = await this.fetchApi.fetch(
      `${baseUrl}/creation-templates/${namespace}/${name}/generated-type`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
      },
    );

    if (response.status === 404) return undefined; // the creation template does not instantiate any specific type

    await handleFailedResponse(response);
    return response.json();
  }

  async resolveSystemTypeOf(
    request: ResolveSystemTypeOfRequest,
    options?: RequestOptions,
  ): Promise<SystemType> {
    const baseUrl = await this.baseUrlPromise;
    const { name, namespace = 'default' } = request.systemRef;
    const response = await this.fetchApi.fetch(
      `${baseUrl}/systems/${namespace}/${name}/type`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
      },
    );
    await handleFailedResponse(response);
    return response.json();
  }

  async resolveComponentTypeOf(
    request: ResolveComponentTypeOfRequest,
    options?: RequestOptions,
  ): Promise<ComponentType> {
    const baseUrl = await this.baseUrlPromise;
    const { name, namespace = 'default' } = request.componentRef;
    const response = await this.fetchApi.fetch(
      `${baseUrl}/components/${namespace}/${name}/type`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
      },
    );
    await handleFailedResponse(response);
    return response.json();
  }

  async resolveDomainTypeOf(
    request: ResolveDomainTypeOfRequest,
    options?: RequestOptions,
  ): Promise<DomainType> {
    const baseUrl = await this.baseUrlPromise;
    const { name, namespace = 'default' } = request.domainRef;
    const response = await this.fetchApi.fetch(
      `${baseUrl}/domains/${namespace}/${name}/type`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
      },
    );
    await handleFailedResponse(response);
    return response.json();
  }

  async isHeadlessComponent(
    request: IsHeadlessComponentRequest,
    options?: RequestOptions,
  ): Promise<boolean> {
    const baseUrl = await this.baseUrlPromise;
    const { name, namespace = 'default' } = request.componentRef;
    const response = await this.fetchApi.fetch(
      `${baseUrl}/components/${namespace}/${name}/variant`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
      },
    );
    await handleFailedResponse(response);
    const { variant } = (await response.json()) as GetComponentVariantResponse;
    return variant === 'headless';
  }

  async getResourceTypes(
    request: GetResourceTypesRequest,
    options?: RequestOptions,
  ): Promise<GetResourceTypesResponse> {
    const baseUrl = await this.baseUrlPromise;
    const queryParams = {
      taxonomyRef: request.taxonomyRef,
    };
    const response = await this.fetchApi.fetch(
      `${baseUrl}/resource-types${encodeQueryParams(queryParams)}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
      },
    );
    await handleFailedResponse(response);
    return response.json();
  }

  async resolveTaxonomyDomainTypes(
    request: ResolveTaxonomyDomainTypesRequest,
    options?: RequestOptions,
  ): Promise<ResolveTaxonomyDomainTypesResponse> {
    const baseUrl = await this.baseUrlPromise;
    const { name, namespace = 'default' } = request.taxonomyRef;
    const response = await this.fetchApi.fetch(
      `${baseUrl}/taxonomies/${namespace}/${name}/domain-types`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
      },
    );
    await handleFailedResponse(response);
    return response.json();
  }
}
