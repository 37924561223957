import { parseEntityRef } from '@backstage/catalog-model';

export const identifierForbiddenCharsRegex = new RegExp(/[ ,.;:]+/g);
export const validEntityNameChars = new RegExp(
  /^([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9]$/,
);

export function generateIdentifier(
  name: string,
  domain: string | Record<string, any>,
): string | null {
  if (!name || !domain) {
    return null;
  }

  const nameWithoutSpecialCharacters = sanitizeEntityName(name);

  if (typeof domain === 'string') {
    const parsedDomain = domain.startsWith('domain:')
      ? parseEntityRef(domain)
      : parseEntityRef(`domain:${domain}`);
    return `${parsedDomain?.name}.${nameWithoutSpecialCharacters}.0`;
  }

  return `${domain?.metadata?.name}.${nameWithoutSpecialCharacters}.0`;
}

export function generateComponentIdentifier(
  name: string,
  system: string,
): string | null {
  if (!name || !system) {
    return null;
  }

  const nameWithoutSpecialCharacters = sanitizeEntityName(name);

  const parsedSystem = system.startsWith('system:')
    ? parseEntityRef(system)
    : parseEntityRef(`system:${system}`);
  return `${parsedSystem?.name}.${nameWithoutSpecialCharacters}`;
}

function sanitizeEntityName(name: string): string {
  return name
    .normalize('NFD')
    .trim()
    .replace(/\p{Diacritic}/gu, '')
    .replace(identifierForbiddenCharsRegex, '-')
    .toLowerCase();
}
