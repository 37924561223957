import React, { useState, memo } from 'react';

import { useNavigate } from 'react-router';
import { useRouteRef } from '@backstage/core-plugin-api';
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import clsx from 'clsx';

import { DataProductPageRoutes } from '../../DataProductPage/DataProductPageRoutes';
import { dataProductRouteRef } from '../../../routes';

type SearchSystemActionsMenuProps = {
  dataProductId: string;
  size?: 'small' | 'medium';
};

const useStyles = makeStyles(
  theme =>
    createStyles({
      actionsMenuButton: {
        padding: theme.spacing(0.5),
        minWidth: '30px',
      },
    }),
  { name: 'SearchSystemActionsMenu' },
);

export const SearchSystemActionsMenu: React.FC<SearchSystemActionsMenuProps> =
  memo(({ dataProductId, size = 'medium' }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const navigate = useNavigate();

    const dataProductRoute = useRouteRef(dataProductRouteRef)({
      id: `${dataProductId}`,
    });

    const dependenciesRoute = `${dataProductRoute}${DataProductPageRoutes.RELATIONS_ROUTE}`;

    const dataContractsRoute = `${dataProductRoute}${DataProductPageRoutes.DATA_CONTRACTS_LINEAGE}`;

    return (
      <>
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          className={clsx(classes.actionsMenuButton)}
          color="secondary"
        >
          <MoreVertIcon fontSize={size} />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={e => {
            e.stopPropagation();
          }}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <MenuItem
            onClick={e => {
              e.stopPropagation();
              navigate(dataProductRoute);
            }}
          >
            <ListItemIcon>
              <WebAssetIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Overview" />
          </MenuItem>
          <MenuItem
            onClick={e => {
              e.stopPropagation();
              navigate(dependenciesRoute);
            }}
          >
            <ListItemIcon>
              <BubbleChartIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Dependencies" />
          </MenuItem>
          <MenuItem
            onClick={e => {
              e.stopPropagation();
              navigate(dataContractsRoute);
            }}
          >
            <ListItemIcon>
              <AccountTreeIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Data Contracts" />
          </MenuItem>
        </Menu>
      </>
    );
  });
