/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { WitboostSystem } from '@agilelab/plugin-wb-builder-common';
import {
  CustomViewDownloader,
  useSelectedRoutedTab,
  WbBreadcrumb,
  WbBreadcrumbs,
  WbFavoriteEntityButton,
  WbHeader,
} from '@agilelab/plugin-wb-platform';
import { builderSoftwareCatalogView } from '@agilelab/plugin-wb-rbac-common';
import {
  DEFAULT_NAMESPACE,
  Entity,
  RELATION_PART_OF,
} from '@backstage/catalog-model';
import {
  Content,
  Link,
  Page,
  Progress,
  RoutedTabs,
  WarningPanel,
} from '@backstage/core-components';
import {
  attachComponentData,
  IconComponent,
  useApi,
  useElementFilter,
  useRouteRef,
  useRouteRefParams,
} from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  entityRouteRef,
  getEntityRelations,
  InspectEntityDialog,
  useAsyncEntity,
} from '@backstage/plugin-catalog-react';
import { usePermission } from '@backstage/plugin-permission-react';
import { Box, Chip, TabProps, useTheme } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import useAsync from 'react-use/lib/useAsync';
import { EntityContextMenu } from '../EntityContextMenu/EntityContextMenu';
import { UnregisterEntityDialog } from '../UnregisterEntityDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      marginLeft: theme.spacing(1.5),
    },
  }),
);

/** @public */
export type EntityLayoutRouteProps = {
  path: string;
  title: string;
  children: JSX.Element;
  if?: (entity: Entity) => boolean;
  tabProps?: TabProps<React.ElementType, { component?: React.ElementType }>;
  // allows to change the actions defined on the Header of the page and use these instead
  overrideHeaderActions?: ReactNode;
};

const dataKey = 'plugin.catalog.entityLayoutRoute';

const Route: (props: EntityLayoutRouteProps) => null = () => null;
attachComponentData(Route, dataKey, true);
attachComponentData(Route, 'core.gatherMountPoints', true); // This causes all mount points that are discovered within this route to use the path of the route itself

function EntityLayoutTitle(props: {
  title: string;
  entity: Entity | undefined;
  children?: ReactElement;
}) {
  const { entity, title, children } = props;
  const theme = useTheme();
  return (
    <Box display="inline-flex" alignItems="center" height="1em" maxWidth="100%">
      <Box
        component="span"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
      >
        {title}
        {children}
      </Box>
      {entity && (
        <WbFavoriteEntityButton
          entity={entity}
          style={{ marginLeft: theme.spacing(2) }}
        />
      )}
    </Box>
  );
}

const customViewIdMap: { [key: string]: string } = {
  outputport: 'builder_component',
  dataproduct: 'builder_system',
};

function headerProps(
  paramKind: string | undefined,
  paramNamespace: string | undefined,
  paramName: string | undefined,
  entity: Entity | undefined,
): { headerTitle: string; headerType: string } {
  const kind = paramKind ?? entity?.kind ?? '';
  const namespace = paramNamespace ?? entity?.metadata.namespace ?? '';
  const name =
    entity?.metadata.title ?? paramName ?? entity?.metadata.name ?? '';
  return {
    headerTitle: `${name}${
      namespace && namespace !== DEFAULT_NAMESPACE ? ` in ${namespace}` : ''
    }`,
    headerType: (() => {
      let t = kind.toLocaleLowerCase('en-US');
      if (entity && entity.spec && 'type' in entity.spec) {
        t += ' — ';
        t += (entity.spec as { type: string }).type.toLocaleLowerCase('en-US');
      }
      return t;
    })(),
  };
}

// NOTE(freben): Intentionally not exported at this point, since it's part of
// the unstable extra context menu items concept below
interface ExtraContextMenuItem {
  title: string;
  Icon: IconComponent;
  onClick: () => void;
  disabled?: boolean;
}

type VisibleType = 'visible' | 'hidden' | 'disable';

// NOTE(blam): Intentionally not exported at this point, since it's part of
// unstable context menu option, eg: disable the unregister entity menu
interface EntityContextMenuOptions {
  disableUnregister: boolean | VisibleType;
}

/** @public */
export interface EntityLayoutProps {
  UNSTABLE_extraContextMenuItems?: ExtraContextMenuItem[];
  UNSTABLE_contextMenuOptions?: EntityContextMenuOptions;
  children?: React.ReactNode;
  NotFoundComponent?: React.ReactNode;
  version?: string;
}

/**
 * EntityLayout is a compound component, which allows you to define a layout for
 * entities using a sub-navigation mechanism.
 *
 * Consists of two parts: EntityLayout and EntityLayout.Route
 *
 * @example
 * ```jsx
 * <EntityLayout>
 *   <EntityLayout.Route path="/example" title="Example tab">
 *     <div>This is rendered under /example/anything-here route</div>
 *   </EntityLayout.Route>
 * </EntityLayout>
 * ```
 *
 * @public
 */
export const EntityLayout = (props: EntityLayoutProps) => {
  const {
    UNSTABLE_extraContextMenuItems,
    UNSTABLE_contextMenuOptions,
    children,
    NotFoundComponent,
    version,
  } = props;
  const { kind, namespace, name } = useRouteRefParams(entityRouteRef);
  const { entity, loading, error } = useAsyncEntity();
  const catalogEntityRoute = useRouteRef(entityRouteRef);
  const catalogApi = useApi(catalogApiRef);
  const location = useLocation();
  const routes = useElementFilter(
    children,
    elements =>
      elements
        .selectByComponentData({
          key: dataKey,
          withStrictError:
            'Child of EntityLayout must be an EntityLayout.Route',
        })
        .getElements<EntityLayoutRouteProps>() // all nodes, element data, maintain structure or not?
        .flatMap(({ props: elementProps }) => {
          if (!entity) {
            return [];
          } else if (elementProps.if && !elementProps.if(entity)) {
            return [];
          }

          return [
            {
              path: elementProps.path,
              title: elementProps.title,
              children: elementProps.children,
              tabProps: elementProps.tabProps,
              overrideHeaderActions: elementProps.overrideHeaderActions,
            },
          ];
        }),
    [entity],
  );

  const { headerTitle } = headerProps(kind, namespace, name, entity);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [inspectionDialogOpen, setInspectionDialogOpen] = useState(false);
  const navigate = useNavigate();
  const myProjectsFrom = location.pathname.includes('my-projects');

  const cleanUpAfterRemoval = useCallback(async () => {
    setConfirmationDialogOpen(false);
    setInspectionDialogOpen(false);
    navigate('/');
  }, [navigate]);

  // Make sure to close the dialog if the user clicks links in it that navigate
  // to another entity.
  useEffect(() => {
    setConfirmationDialogOpen(false);
    setInspectionDialogOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const e = entity as WitboostSystem;
  const classes = useStyles();

  const isComponent = useMemo(
    () => entity?.kind.toLocaleLowerCase('en-US'),
    [entity?.kind],
  );

  const partOfSystemRelations = getEntityRelations(entity, RELATION_PART_OF, {
    kind: 'system',
  });

  const { value: dpEntity } = useAsync(async () => {
    if (isComponent && partOfSystemRelations.length)
      return catalogApi.getEntityByRef(partOfSystemRelations[0]);
    return undefined;
  }, [catalogApi]);

  // the selected <Routedtab /> and its props
  const { route: selectedTab } = useSelectedRoutedTab(routes);
  const { allowed } = usePermission({
    permission: builderSoftwareCatalogView,
  });
  const customViewId =
    customViewIdMap[entity?.spec?.type as string] ?? 'builder_system';

  return (
    <Page themeId={entity?.spec?.type?.toString() ?? 'home'}>
      <WbHeader
        title={
          <EntityLayoutTitle
            title={e?.spec?.mesh?.name || headerTitle}
            entity={entity!}
          >
            <>
              {entity && (
                <EntityContextMenu
                  UNSTABLE_extraContextMenuItems={
                    UNSTABLE_extraContextMenuItems
                  }
                  UNSTABLE_contextMenuOptions={UNSTABLE_contextMenuOptions}
                  onUnregisterEntity={() => setConfirmationDialogOpen(true)}
                  onInspectEntity={() => setInspectionDialogOpen(true)}
                  additionalItems={
                    <CustomViewDownloader customViewIds={[customViewId]} />
                  }
                />
              )}
              {e?.spec?.mesh?.version && (
                <Chip
                  label={version ?? e?.spec?.mesh?.version}
                  color="primary"
                  className={classes.chip}
                />
              )}
            </>
          </EntityLayoutTitle>
        }
        breadcrumbs={
          <WbBreadcrumbs aria-label="breadcrumb">
            <WbBreadcrumb>Builder</WbBreadcrumb>
            {!myProjectsFrom && entity && entity.kind && (
              <WbBreadcrumb
                to={
                  allowed
                    ? `/catalog?filters%5Bkind%5D=${entity.kind}`
                    : undefined
                }
              >
                Software catalog
              </WbBreadcrumb>
            )}
            {/* TODO: Edit when hierarchies are implemented */}
            {myProjectsFrom && entity && (
              <WbBreadcrumb to="/my-projects/catalog">My projects</WbBreadcrumb>
            )}
            {dpEntity && (
              <WbBreadcrumb
                to={catalogEntityRoute({
                  kind: dpEntity.kind,
                  namespace: dpEntity.metadata.namespace || 'default',
                  name: dpEntity.metadata.name,
                })}
              >
                {(dpEntity as WitboostSystem)?.spec?.mesh?.name}
              </WbBreadcrumb>
            )}
            <WbBreadcrumb>{e?.spec?.mesh?.name || headerTitle}</WbBreadcrumb>
          </WbBreadcrumbs>
        }
        pageTitleOverride={headerTitle}
      >
        {selectedTab && selectedTab.overrideHeaderActions}
      </WbHeader>

      {loading && <Progress />}

      {entity && <RoutedTabs routes={routes} />}

      {error && (
        <Content>
          <Alert severity="error">{error.toString()}</Alert>
        </Content>
      )}

      {!loading && !error && !entity && (
        <Content>
          {NotFoundComponent ? (
            NotFoundComponent
          ) : (
            <WarningPanel title="Entity not found. The entity does not exist or you do not have the required permissions to view it.">
              There is no {kind} with the requested kind, namespace, and name.
              For further details, please see the Backstage documentation about{' '}
              <Link to="https://backstage.io/docs/features/software-catalog/references">
                Entity references
              </Link>
              .
            </WarningPanel>
          )}
        </Content>
      )}

      <UnregisterEntityDialog
        open={confirmationDialogOpen}
        entity={entity!}
        onConfirm={cleanUpAfterRemoval}
        onClose={() => setConfirmationDialogOpen(false)}
      />
      <InspectEntityDialog
        open={inspectionDialogOpen}
        entity={entity!}
        onClose={() => setInspectionDialogOpen(false)}
      />
    </Page>
  );
};

EntityLayout.Route = Route;
