import React, { createContext, ReactNode, RefObject, useContext } from 'react';
import { PartialPopper } from './types';

type WbPopoverContextType = {
  popperRef: RefObject<PartialPopper>;
};

const defaultWbPopoverContext = null;

export const WbPopoverContext = createContext<WbPopoverContextType | null>(
  defaultWbPopoverContext,
);

export const useWbPopover = () => {
  const contextValue = useContext(WbPopoverContext);

  if (!contextValue) {
    throw new Error(
      'useWbPopoverContext has to be used within <WbPopoverContext.Provider>',
    );
  }
  return contextValue;
};

export const WbPopoverContextProvider: React.FC<
  { children: ReactNode } & WbPopoverContextType
> = ({ children, popperRef }) => {
  return (
    <WbPopoverContext.Provider value={{ popperRef }}>
      {children}
    </WbPopoverContext.Provider>
  );
};
