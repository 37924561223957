import { z } from 'zod';
import { Brand } from './utils';
import { CompoundEntityRef } from '@backstage/catalog-model';

export const userRefSchema = z.string().refine(s => s.startsWith('user:'));
export type UserEntityRef = Brand<
  z.infer<typeof userRefSchema>,
  'UserEntityRef'
>;

export function isUserEntityRef(ref: string): boolean {
  return userRefSchema.safeParse(ref).success;
}

export const groupRefSchema = z.string().refine(s => s.startsWith('group:'));
export type GroupEntityRef = Brand<
  z.infer<typeof groupRefSchema>,
  'GroupEntityRef'
>;

export const systemRefSchema = z.string().refine(s => s.startsWith('system:'));
export type SystemEntityRef = Brand<
  z.infer<typeof systemRefSchema>,
  'SystemEntityRef'
>;

export const systemUrnSchema = z
  .string()
  .refine(s => s.startsWith('urn:dmb:dp:')); // TODO not only data mesh affects this
export type SystemUrn = Brand<z.infer<typeof systemUrnSchema>, 'SystemUrn'>;

export type EnvironmentExternal = {
  /**
   * of the external environment
   */
  id: number;
  name: string;
};

export type Environment = {
  name: string;
  /**
   * the smaller is the number, the more is prioritized.
   */
  priority: number;
};

/**
 * This type is defined here because it is used by multiple plugins.
 * To avoid replicating multiple times, we defined it here, preventing circular dependencies.
 */
export enum NotificationType {
  QUESTION = 'Question',
  ACCESS_REQUEST = 'AccessRequest',
  INFORMATIVE = 'Informative',
  ACCESS_RESPONSE = 'AccessResponse',
  REVOKE_REQUEST = 'RevokeRequest',
  REVOKE_RESPONSE = 'RevokeResponse',
}

export type TypedCompoundEntityRef<Kind extends string> = CompoundEntityRef & {
  kind: Kind;
};
