import { WbCardActionButton } from '@agilelab/plugin-wb-platform';
import { Entity } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { Box, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormatListBulleted from '@material-ui/icons/FormatListBulleted';
import parseGitUrl from 'git-url-parse';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useEditorPageContext } from '../EditorPage/context/useEditorPageContext';
import { checkExistingEditTemplate } from './utils';

const useStyles = makeStyles(theme => ({
  editButton: {
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  optionButton: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'white',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: '2px',
    right: '2px',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));

interface Props {
  entity: Entity;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
}

export const EditButton: React.FC<Props> = ({
  entity,
  variant = 'contained',
}) => {
  const classes = useStyles();
  const catalogApi = useApi(catalogApiRef);
  const navigate = useNavigate();
  const { entitiesAndBranches } = useEditorPageContext();

  const defaultBranch = entity?.metadata?.annotations?.['backstage.io/view-url']
    ? parseGitUrl(entity?.metadata?.annotations['backstage.io/view-url']).ref ??
      'master'
    : 'master';

  // gets the current selected branch of the entity if available or fallbacks to defaultBranch
  const selectedBranch =
    Object.entries(entitiesAndBranches).filter(
      ([k]) => k.split('/')[1] === entity.metadata.name,
    )[0]?.[1] || defaultBranch;

  const hrefEditorWizard = `/scaffolder/${encodeURIComponent(
    entity?.metadata?.namespace ?? 'default',
  )}/${encodeURIComponent(
    entity?.kind?.toLocaleLowerCase(),
  )}/${encodeURIComponent(
    entity?.metadata?.name,
  )}/editor-wizard?${new URLSearchParams({ targetBranch: selectedBranch })}`;
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    checkExistingEditTemplate({ catalogApi, entity, setErrorMessage });
  }, [entity, setErrorMessage, catalogApi]);

  return (
    <Box style={{ paddingRight: '5px' }}>
      <Tooltip
        title={
          errorMessage !== ''
            ? errorMessage
            : `Edit on branch ${selectedBranch}`
        }
      >
        <span>
          <WbCardActionButton
            label="Edit"
            disableRipple
            startIcon={<FormatListBulleted />}
            onClick={() => navigate(hrefEditorWizard)}
            disabled={errorMessage !== ''}
            color="primary"
            size="small"
            variant={variant}
            className={classes.editButton}
            style={{ textDecoration: 'none' }}
          />
        </span>
      </Tooltip>
    </Box>
  );
};
