import { AppTheme } from '@backstage/core-plugin-api';
import {
  BackstageTypography,
  PageTheme,
  UnifiedTheme,
  UnifiedThemeOptions,
  UnifiedThemeProvider,
  colorVariants,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';
import type { PaletteOptions as PaletteOptionsV4 } from '@material-ui/core/styles/createPalette';
import createPalette from '@material-ui/core/styles/createPalette';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { Shadows } from '@material-ui/core/styles/shadows';
import {
  PaletteOptions as PaletteOptionsV5,
  ThemeOptions as ThemeOptionsV5,
} from '@mui/material/styles';
import React from 'react';
import { WitboostPalette } from './WitboostPalette';

// an array used to override the default 25 mui shadows at indexes specified by i
const MUI_SHADOWS_OVERRIDE = [
  { i: 1, value: `0px 2px 5px #92929240` },
  { i: 2, value: `0px 4px 16px #92929233` },
  { i: 23, value: `2px 0px 50px #92929226` },
  { i: 24, value: `0px 2px 50px #92929226` },
];

export const witboostComponents: ThemeOptionsV5['components'] = {
  BackstageHeader: {
    styleOverrides: {
      header: ({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'none',
        boxShadow: 'none',
        padding: theme.spacing(2, 3),
      }),
      title: ({ theme }) => ({
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontSize: theme.typography.h5.fontSize,
      }),
      subtitle: ({ theme }) => ({
        marginTop: '12px',
        letterSpacing: '-0.015rem',
        color: theme.palette.text.primary,
      }),
      type: ({ theme }) => ({
        color: theme.palette.text.primary,
      }),
      rightItemsBox: ({ theme }) => ({
        color: theme.palette.primary.main,
        margin: 0,
      }),
    },
  },
  BackstageHeaderLabel: {
    styleOverrides: {
      label: ({ theme }) => ({
        color: theme.palette.primary.main,
      }),
      value: ({ theme }) => ({
        color: theme.palette.primary.main,
      }),
      root: ({ theme }) => ({
        color: theme.palette.primary.main,
      }),
    },
  },
  BackstageContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: `0 !important`,
        background: `${theme.palette.background.paper} 0% 0% no-repeat padding-box`,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(3),
      }),
    },
  },
  BackstageHeaderTabs: {
    styleOverrides: {
      selected: ({ theme }) => ({
        color: `${theme.palette.primary.main} !important`,
      }),
      tabsWrapper: ({ theme }) => ({
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        '& button': {
          padding: `0 !important`,
          margin: `0 8px !important`,
          minWidth: 0,
          color: theme.palette.text.primary,
          opacity: 1,

          textTransform: 'capitalize',
          fontSize: '1.125rem',
          fontWeight: 500,
          letterSpacing: '0.009rem',
          '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'inherit',
          },
        },
      }),
    },
  },
  BackstageInfoCard: {
    styleOverrides: {
      header: ({ theme }) => ({
        padding: theme.spacing(1.5, 2),
        '& span': {
          margin: 0,
        },
      }),
      headerTitle: {
        fontWeight: 500,
      },
      headerSubheader: {
        paddingTop: 0,
      },
    },
  },
  BackstageTable: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& > div': {
          backgroundColor: theme.palette.common.white,
        },
        '& tr:hover': {
          backgroundColor: (theme.palette as unknown as WitboostPalette).bkg
            .primary,
        },
      }),
    },
  },
  BackstageTableToolbar: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(2, 0, 2, 2),
      }),
      title: ({ theme }) => ({
        '& > *': {
          fontSize: theme.typography.h6.fontSize,
          margin: 0,
        },
      }),
    },
  },
  BackstageTableHeader: {
    styleOverrides: {
      header: ({ theme }) => ({
        backgroundColor: theme.palette.primary.main,
        '& *': {
          fill: theme.palette.getContrastText(theme.palette.primary.dark),
          color: `${theme.palette.getContrastText(
            theme.palette.primary.dark,
          )} !important`,
        },
      }),
    },
  },
  // This additional theme customization is required to set the DismissableBanner closeButtonColor
  // This problem is solved in the latest Backstage version (set theme.palette.banner.closeButtonColor in basicTheme below)
  BackstageDismissableBanner: {
    styleOverrides: {
      content: ({ theme }) => ({
        color: theme.palette.banner.text,
      }),
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: (theme.palette as unknown as WitboostPalette).bkg
          .secondary,
      }),
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        fontSize: '1rem',
      },
      a: {
        color: 'inherit',
        textDecoration: 'none',
      },
    },
  },
  MuiPaper: {
    defaultProps: {
      // uses the boxShadow at index 1 from the theme
      elevation: 1,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        // remove the border when paper is not used as an elevated surface
        '&$elevation0': {
          border: 'none',
        },
        border: `1px solid ${theme.palette.grey[200]}`,
        backgroundColor: '#ffffff',
      }),
    },
  },
  MuiPopover: {
    defaultProps: {
      // uses the boxShadow at index 2 from the theme
      elevation: 2,
    },
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      ol: ({ theme }) => ({
        marginTop: theme.spacing(1),
        color: '#B0BEC5',
      }),
      li: ({ theme }) => ({
        '&:last-child > p': {
          color: `${theme.palette.primary.main} !important`,
        },
        '& a:hover': {
          color: theme.palette.primary.main,
        },
      }),
    },
  },
  MuiBackdrop: {
    styleOverrides: {
      root: {
        backgroundColor: 'rgba(32, 89, 97, .6)',
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: ({ theme }) => ({
        backgroundColor: theme.palette.common.white,
        boxShadow: 'none',
      }),
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: 'inherit',
        minWidth: '36px',
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& > h2': {
          letterSpacing: '0px',
          fontWeight: 500,
          color: `${theme.palette.primary.main} !important`,
        },
      }),
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(3),
      }),
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: ({ theme }) => ({
        boxShadow: 'none',
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(3),
      }),
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      content: {
        overflow: 'auto',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '20px',
        textTransform: 'none',
        lineHeight: '1.43',
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      outlinedPrimary: {
        border: '1px solid',
        '&:hover': {
          border: '1px solid',
        },
      },
      sizeSmall: {
        textTransform: 'none',
      },
      sizeLarge: {
        textTransform: 'none',
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& th': {
          color: theme.palette.primary.main,
          textTransform: 'uppercase',
          fontWeight: 700,
          '& span': {
            color: `${theme.palette.primary.main}`,
            textTransform: 'uppercase',
            fontWeight: 700,
          },
        },
      }),
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&$selected': {
          backgroundColor: `${
            (theme.palette as unknown as WitboostPalette).bkg.primary
          } !important`,
        },
      }),
      hover: ({ theme }) => ({
        '&:hover': {
          backgroundColor: `${
            (theme.palette as unknown as WitboostPalette).bkg.primary
          } !important`,
          color: theme.palette.getContrastText(theme.palette.primary.light),
        },
      }),
    },
  },
  MuiTableSortLabel: {
    styleOverrides: {
      active: ({ theme }) => ({
        color: `${theme.palette.primary.main} !important`,
      }),
      root: ({ theme }) => ({
        color: theme.palette.primary.main,
      }),
      icon: ({ theme }) => ({
        fill: theme.palette.primary.main,
      }),
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontSize: '1rem',
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
      }),
      stickyHeader: ({ theme }) => ({
        backgroundColor: theme.palette.background.default,
      }),
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: (theme.palette as unknown as WitboostPalette).bkg
          .primary,
        color: theme.palette.primary.main,
        padding: theme.spacing(1.5, 2),
      }),
      title: ({ theme }) => ({
        fontWeight: 700,
        margin: 0,
        fontSize: theme.typography.h6.fontSize,
      }),
      action: {
        marginTop: 0,
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.common.white,
      }),
    },
  },
  MuiChip: {
    styleOverrides: {
      colorPrimary: ({ theme }) => ({
        backgroundColor: (theme.palette as unknown as WitboostPalette).bkg
          .primary,
        color: theme.palette.text.primary,
      }),
      deleteIconColorPrimary: ({ theme }) => ({
        color: theme.palette.text.primary,

        '&:hover': {
          color: theme.palette.text.primary,
        },
      }),
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&$disabled': {
          background: theme.palette.grey[100],
        },
      }),
    },
  },
};

export const witboostTypography: BackstageTypography & TypographyOptions = {
  // This must ALWAYS be equal to the html font size in the CSS
  htmlFontSize: 14,
  fontFamily: 'Poppins',
  fontSize: 14,
  h1: {
    fontWeight: 400,
    fontSize: '2.285rem',
    lineHeight: 1.5,
    marginBottom: 10,
  },
  h2: {
    fontWeight: 400,
    fontSize: '2rem',
    lineHeight: 1.5,
    marginBottom: 8,
  },
  h3: {
    fontWeight: 400,
    fontSize: '1.714rem',
    lineHeight: 1.5,
    marginBottom: 6,
  },
  h4: {
    fontWeight: 500,
    fontSize: '1.428rem',
    lineHeight: 1.5,
    marginBottom: 6,
  },
  h5: {
    fontWeight: 400,
    fontSize: '1.285rem',
    lineHeight: 1.5,
    marginBottom: 4,
  },
  h6: {
    fontWeight: 400,
    fontSize: '1.142rem',
    lineHeight: 1.5,
    marginBottom: 2,
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: '1.285rem',
    lineHeight: 1.5,
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: '1.142rem',
    lineHeight: 1.5,
  },
  body1: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  body2: {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.5,
  },
  button: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  caption: {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.5,
  },
  overline: {
    fontWeight: 400,
    fontSize: '0.785rem',
    lineHeight: 1.5,
  },
};

export const witboostPageTheme: Record<string, PageTheme> = {
  home: genPageTheme({ colors: ['#00acc2', '#00778c'], shape: shapes.wave }),
  documentation: genPageTheme({
    colors: colorVariants.pinkSea,
    shape: shapes.wave2,
  }),
  tool: genPageTheme({ colors: colorVariants.purpleSky, shape: shapes.round }),
  service: genPageTheme({
    colors: colorVariants.marineBlue,
    shape: shapes.wave,
  }),
  website: genPageTheme({ colors: colorVariants.veryBlue, shape: shapes.wave }),
  library: genPageTheme({ colors: colorVariants.rubyRed, shape: shapes.wave }),
  other: genPageTheme({ colors: colorVariants.darkGrey, shape: shapes.wave }),
  app: genPageTheme({ colors: colorVariants.toastyOrange, shape: shapes.wave }),
  apis: genPageTheme({ colors: colorVariants.teal, shape: shapes.wave2 }),
  card: genPageTheme({ colors: colorVariants.greens, shape: shapes.wave }),
};

const witboostPaletteOptions: PaletteOptionsV4 = {
  ...palettes.light,
  witboostLogo: '#ee961a',
  blueDeep: '#20284d',
  blueSky: '#00acc4',
  lightBlue: '#6cdce0',
  bkg: {
    primary: '#e6f7f9',
    secondary: '#edf0f2',
    accent: '#f2edff',
    accentSecondary: '#fdf1fb',
    success: '#e6faee',
    warning: '#fef6e9',
    error: '#ffeeee',
  },
  softened: {
    primary: '#b0e5ed',
    secondary: '#c8d1d8',
    accent: '#d6c8ff',
    accentSecondary: '#fad3f1',
    success: '#b0eeca',
    warning: '#fde4bb',
    error: '#ffc9c9',
  },
  primary: {
    light: '#8ad9e4',
    main: '#00acc4',
    dark: '#005f6c',
    contrastText: '#FFFFFF',
  },
  secondary: {
    light: '#aebac5',
    main: '#4e6980',
    dark: '#2b3a46',
    contrastText: '#FFFFFF',
  },
  white: '#FFFFFF',
  black: '#000000',
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
  },
  blueGrey: {
    50: '#eceff1',
    100: '#cfd8dc',
    200: '#b0bec5',
    300: '#90a4ae',
    400: '#78909c',
    500: '#607d8b',
    600: '#546e7a',
    700: '#455a64',
    800: '#37474f',
    900: '#263238',
  },
  accent: {
    light: '#c3adff',
    main: '#7c4dff',
    dark: '#442a8c',
  },
  accentSecondary: {
    light: '#f7beeb',
    main: '#ee72d3',
    dark: '#833f74',
  },
  success: {
    light: '#8ae6b0',
    main: '#00c853',
    dark: '#006e2e',
  },
  warning: {
    light: '#fcd79b',
    main: '#f9a825',
    dark: '#895c14',
  },
  error: {
    light: '#ffafaf',
    main: '#ff5252',
    dark: '#8c2d2d',
  },
  favs: '#FFD600',
  // Useful to override default MUI theme backstage
  text: {
    primary: '#4E6980',
    secondary: '#4E6980',
  },
  info: {
    light: '#34548a',
    main: '#34548a',
    dark: '#263d65',
  },
  background: {
    default: '#FFFFFF',
    paper: '#fafafa',
  },
  // TODO: understand how to convert to existing color
  banner: {
    info: '#343b58',
    error: '#F3ECED',
    text: '#381A20',
    link: '#381A20',
  },
  marketplaceGraph: {
    groupRange: {
      light: '#cbe6eb',
      dark: '#6ebcce',
    },
    groupLabel: '#000000',
    groupTooltip: {
      main: '#54687f',
      contrastText: '#FFFFFF',
    },
    heatmapRange: {
      light: '#B0EECA',
      dark: '#1430B8',
    },
    systemMain: {
      main: '#7274d0',
      contrastText: '#FFFFFF',
    },
    systemDisconnected: '#7274d04d',
    systemInput: '#3b5488',
    systemOutput: '#ee72d3',
    systemLabel: '#FFFFFF',
    systemTooltip: {
      main: '#54687f',
      contrastText: '#FFFFFF',
    },
    navigationGroupSelected: '#00acc414',
  },
  relationsGraph: {
    error: '#F6675C',
    warning: '#F9A825',
    dataContract: '#90A4AE',
    system: '#4F6980',
    resource: '#388AA1',
    component: '#00AEC5',
    domain: '#388AA1',
    user: '#388AA1',
    consumer: '#1BACAD',
    consumption: '#607D8B',
    taxonomy: '#F9A825',
  },
  // map taxonomy urn -> color
  taxonomies: {},
  charts: [
    '#7c4dff',
    '#8ad9e4',
    '#ee72d3',
    '#fde4bb',
    '#ff5252',
    '#833f74',
    '#00acc4',
    '#ffafaf',
    '#1430B8',
  ],
};

export const witboostPalette: PaletteOptionsV4 & PaletteOptionsV5 =
  createPalette(witboostPaletteOptions);

// overrides basicTheme shadows array with the `MUI_SHADOWS_OVERRIDE` here defined
// TODO check how to handle this with the MUI5 theme update
export const createShadows = (defaultShadows: Shadows) => {
  MUI_SHADOWS_OVERRIDE.forEach(({ i, value }) => (defaultShadows[i] = value));

  return defaultShadows as Shadows;
};

export function createWitboostTheme(
  options: UnifiedThemeOptions,
): UnifiedTheme {
  const theme = createUnifiedTheme(options);
  // this is needed to override the default MUI shadows
  const themeV4 = theme.getTheme('v4');
  if (themeV4) {
    themeV4.shadows = createShadows(themeV4.shadows);
  }
  return theme;
}

export const WitboostTheme: UnifiedTheme = createWitboostTheme({
  palette: witboostPalette,
  typography: witboostTypography,
  pageTheme: witboostPageTheme,
  defaultPageTheme: 'home',
  components: witboostComponents,
});

export const LightAgileTheme: Partial<AppTheme> & Omit<AppTheme, 'theme'> = {
  id: 'theme-light',
  title: 'Light',
  variant: 'light',
  Provider: ({ children }) => {
    return <UnifiedThemeProvider theme={WitboostTheme} children={children} />;
  },
};
