import {
  WitboostSystem,
  WitboostTemplate,
} from '@agilelab/plugin-wb-builder-common';
import { CatalogClientExtension } from '@agilelab/plugin-wb-catalog-extension-common';
import { WbCardActionButton } from '@agilelab/plugin-wb-platform';
import { practiceShaperApiRef } from '@agilelab/plugin-wb-practice-shaper';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { Paper, Tooltip, capitalize } from '@material-ui/core';
import MenuList from '@material-ui/core/MenuList';
import Popover from '@material-ui/core/Popover';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import _ from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import useAsync from 'react-use/lib/useAsync';
import { extensionApiRef } from '../../CatalogExtensionApiRef';
import { ClickableMenuItem } from './ClickableMenuItem';
import { SubMenu } from './SubMenu';

const extractType = (entity: WitboostTemplate) => {
  return entity.metadata.classDetails
    ? entity.metadata.classDetails.pluralizedDisplayName ??
        entity.metadata.classDetails.displayName ??
        'Others'
    : entity.metadata.name;
};

export function ComponentTypeSelectButton({
  system,
}: {
  system: WitboostSystem;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const navigate = useNavigate();
  const catalogApi = useApi(extensionApiRef) as CatalogClientExtension;
  const identityApi = useApi(identityApiRef);
  const practiceShaperApi = useApi(practiceShaperApiRef);
  const knownSystem = system.metadata.name;
  const knownDomain = system.spec.domain;
  const {
    value: templateValue,
    loading: templateLoading,
    error: templateError,
  } = useAsync(async () => {
    const { token } = await identityApi.getCredentials();

    const systemType = await practiceShaperApi.resolveSystemTypeOf({
      systemRef: {
        name: system.metadata.name,
        namespace: system.metadata.namespace,
      },
    });

    const validComponentTypes = (
      await practiceShaperApi.getComponentTypes(
        {
          filter: {
            partOfEntityRef: stringifyEntityRef(systemType),
          },
        },
        { token },
      )
    ).items.map(c => stringifyEntityRef(c));

    // if there are no suitable component types, there can't be suitable templates
    if (validComponentTypes.length === 0) return {};

    const templates = (
      await practiceShaperApi.getCreationTemplatesByGeneratedType(
        {
          generatedTypeRef: validComponentTypes,
        },
        { token },
      )
    ).items;

    const templatesGroupedByType = _.groupBy(templates, extractType);
    return Object.fromEntries(
      Object.entries(templatesGroupedByType)
        .map(([key, value]) => [
          key,
          value.filter(
            template =>
              template.spec.type !== 'dataproduct' &&
              template.metadata.classDetails?.type?.toLowerCase() !==
                'systemtype',
          ),
        ])
        .filter(([key, value]) => key !== 'dataproduct' && value.length),
    );
  }, [catalogApi]);

  const onOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(undefined);
  };

  const navigationStatePayload = {
    system: system.metadata.name,
    domain: system.spec.domain,
  };

  const disabled =
    !!templateError ||
    !templateValue ||
    Object.entries(templateValue).length === 0;

  const getFormData = () => {
    const baseFormData = `?formData=%7B"domain"%3A"${knownDomain}"%2C`;
    return `${baseFormData}"dataproduct"%3A"system:${knownSystem}"%2C"parentRef"%3A"system:${knownSystem}"%7D`;
  };

  return (
    <>
      <Tooltip
        title={
          disabled
            ? 'No suitable Component Templates were found'
            : 'Add a new Component by choosing a Template'
        }
      >
        <span>
          <WbCardActionButton
            onClick={onOpen}
            label="Add"
            disabled={disabled}
            icon={<AddCircleIcon />}
          />
        </span>
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ zIndex: 0 }} // This field is important in order for the submenu to be "sticky"
      >
        {!templateLoading && !templateError ? (
          <MenuList style={{ background: 'white', padding: 0 }}>
            {Object.keys(templateValue!).map((templateType: string) => (
              <SubMenu key={templateType} label={`${capitalize(templateType)}`}>
                {templateValue![templateType]
                  .slice(0, 5)
                  .map((entity: WitboostTemplate) => {
                    const formData = getFormData();
                    return (
                      <Paper
                        key={entity.metadata.name}
                        style={{ borderRadius: 0, background: 'white' }}
                      >
                        <ClickableMenuItem
                          onClick={() =>
                            navigate(
                              `/scaffolder/templates/default/${
                                entity.metadata.name
                              }${knownDomain && knownSystem ? formData : ''}`,
                              {
                                state: navigationStatePayload,
                              },
                            )
                          }
                          label={entity.metadata.title}
                        />
                      </Paper>
                    );
                  })}
                <Paper style={{ borderRadius: 0, background: 'white' }}>
                  <ClickableMenuItem
                    onClick={() => {
                      const url = `/templates?category=all&filters[generates]=${templateValue[templateType][0].spec.generates}`;
                      navigate(url, {
                        state: navigationStatePayload,
                      });
                    }}
                    label="Show all..."
                  />
                </Paper>
              </SubMenu>
            ))}
          </MenuList>
        ) : (
          <></>
        )}
      </Popover>
    </>
  );
}
