import { WbLink, getTruncateMaxLinesStyle } from '@agilelab/plugin-wb-platform';
import { Tooltip, makeStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(
  theme => ({
    root: {
      fontSize: theme.typography.h5.fontSize,
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
    regular: {
      ...getTruncateMaxLinesStyle(2, false),
    },
    concise: {
      wordBreak: 'break-all',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: '450px',
      display: 'block',
      textOverflow: 'ellipsis',
      fontSize: theme.typography.body1.fontSize,
    },
  }),
  { name: 'ResultTitle' },
);

type ResultTitleProps = {
  title: string;
  location: string;
  concise?: boolean;
};

export const ResultTitle = ({ title, location, concise }: ResultTitleProps) => {
  const classes = useStyles();

  return (
    <Tooltip title={title}>
      <div>
        <WbLink
          underlineLink="none"
          route={location}
          text={title}
          className={clsx(
            classes.root,
            concise ? classes.concise : classes.regular,
          )}
        />
      </div>
    </Tooltip>
  );
};
